/**
 *
 * @param {*} parent
 * dependency "vue-izitoast" npm package
 *
    import VueIziToast from 'vue-izitoast';
    import 'izitoast/dist/css/iziToast.css';
    Vue.use(VueIziToast);
 */
function NOTIFICATIONS(parent) {
    this.parent = parent;

    this.notificationSystem = {
        options: {
            show: {
                theme: 'dark',
                icon: 'icon-person',
                position: 'topCenter',
                progressBarColor: 'rgb(0, 255, 184)',
                buttons: [
                    ['<button>Ok</button>', function(instance, toast) {
                        parent.console.log(instance, toast);
                        alert("Hello world!");
                    }, true],
                    ['<button>Close</button>', function(instance, toast) {
                        instance.hide({
                            transitionOut: 'fadeOutUp',
                            onClosing: function(instance, toast, closedBy) {
                                parent.console.log(instance, toast);
                                parent.console.info('closedBy: ' + closedBy);
                            }
                        }, toast, 'buttonName');
                    }]
                ],
                onOpening: function(instance, toast) {
                    parent.console.log(instance, toast);
                    parent.console.info('callback abriu!');
                },
                onClosing: function(instance, toast, closedBy) {
                    parent.console.log(instance, toast);
                    parent.console.info('closedBy: ' + closedBy);
                }
            },
            ballon: {
                balloon: true,
                position: 'bottomCenter'
            },
            info: {
                position: 'bottomLeft'
            },
            success: {
                position: 'bottomRight'
            },
            warning: {
                position: 'topLeft'
            },
            error: {
                position: 'topRight',
                overlay: true,
                overlayClose: true,
            },
            question: {
                timeout: 20000,
                close: false,
                overlay: true,
                toastOnce: true,
                id: 'question',
                zindex: 999999,
                title: '',
                message: 'Are you sure about that?',
                position: 'center',
                buttons: [
                    ['<button><b>YES</b></button>', function(instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }, true],
                    ['<button>NO</button>', function(instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }]
                ],
                onClosing: function(instance, toast, closedBy) {
                    parent.console.log(instance, toast);
                    parent.console.info('Closing | closedBy: ' + closedBy);
                },
                onClosed: function(instance, toast, closedBy) {
                    parent.console.log(instance, toast);
                    parent.console.info('Closed | closedBy: ' + closedBy);
                }
            }
        }
    };
}

NOTIFICATIONS.prototype = {
    // Basic alert
    show() {
        // this.parent.app.$toast.show('Welcome!', 'Hey', this.notificationSystem.options.show);
        // this.parent.app.$toast.show('Welcome!', 'Hey', this.notificationSystem.options.ballon);
    },

    /**
     *
     * @param obj
     * @title string
     * @message string
     */
    success(obj) {
        let msgObj = obj ? obj : {};
        this.parent.app.$toast.success(msgObj.message || 'Process is successfully completed!', msgObj.title || 'Success', this.notificationSystem.options.success);
    },

    // Error message
    error(obj) {
        let msgObj = obj ? obj : {};
        this.parent.app.$toast.error(msgObj.message || 'Illegal operation', msgObj.title || 'Error', this.notificationSystem.options.error);
    },

    // Warning
    warning(obj) {
        let msgObj = obj ? obj : {};
        this.parent.app.$toast.warning(msgObj.message || 'You forgot important data', msgObj.title || 'Warning', this.notificationSystem.options.warning);
    },

    // Basic alert
    info(obj) {
        let msgObj = obj ? obj : {};
        this.parent.app.$toast.info(msgObj.message || 'Information', msgObj.title || 'Info', this.notificationSystem.options.info);
    },

    confirm(obj, callback) {
        let msgObj = obj ? obj : {};
        this.notificationSystem.options.question.buttons = [
            [`<button><b>${this.parent.app.$t('_common.YES')}</b></button>`, function(instance, toast) {
                callback(true);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            [`<button><b>${this.parent.app.$t('_common.NO')}</b></button>`, function(instance, toast) {
                callback(false);
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }]
        ];
        this.parent.app.$toast.question(msgObj.message || '', msgObj.title || '', this.notificationSystem.options.question);
    }
}

export default NOTIFICATIONS;
