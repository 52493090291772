<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img src="@/assets/images/pages/not-authorized.png" alt="graphic-not-authorized" class="mx-auto mb-4">
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">You are not authorized!</h1>
      <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">paraphonic unassessable foramination Caulopteris worral
        Spirophyton encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area bertram plotting
        unstarting quarterstaff.</p>

      <div class="flex-wrap justify-between mb-3">
        <vs-button style="margin-right: 15px" size="large" to="/">Back to Home</vs-button>
        <vs-button style="margin-right: 15px" size="large" @click="onLoginClicked">Login</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    methods: {
      onLoginClicked() {
        window.location = this.$keycloak.createLogoutUrl({redirectUri: window.location.origin});
      }
    }
  }
</script>
