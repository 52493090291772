import Vue from 'vue';
import i18n from '@/i18n/i18n.js'

let ENUMS = {
  API: {
    GetRoleTypes: "/api/User/GetRoleTypes/",
    FileUpload: {
      Upload: process.env.VUE_APP_ORCHESTRATOR_SERVICE + "/api/Authentication/FileUpload/",
      Single: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/single", //video nesnesi var backend
      Unreal: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/store/unreal"
    },
    Assets: {
      Single: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/store/assets/single/"
    },
    Videos: {
      Single: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/store/videos/single/"
    },
    Images: {
      Single: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/store/images/single"
    },
    Pdf: {
      Single: process.env.VUE_APP_STORAGE_SERVICE + "/fileupload/store/pdf/single"
    },
    Drive: process.env.VUE_APP_XPERDRIVE_SERVICE ? {
      Single: process.env.VUE_APP_XPERDRIVE_SERVICE + "/api/File/GetUserNonEncryptedVideos",
      MakeMyVideoToPublicize: process.env.VUE_APP_XPERDRIVE_SERVICE + "/api/File/MakeMyVideoToPublicize",
    } : null,
    Lexicon: {
      Topic: "/api/Lexicon/Topic/",
      AddAsset: "/api/Lexicon/AddAsset/",
    },
    Meeting: "/api/Lexicon/Meeting",
    ListMeeting: "/api/Lexicon/ListMeetings",
    Meeting_User: "/api/Lexicon/Meeting/User/",
    Meeting_DeleteUser: "/api/Lexicon/Meeting/DeleteUser/",
    AddTerm: "/api/Glossary/AddTerm",
    UpdateTerm: "/api/Glossary/UpdateTerm",
    RemoveTerm: "/api/Glossary/RemoveTerm",
    Topic: "/api/Lexicon/Topic",
    AssignedTopic: "/api/Lexicon/AssignedTopics",
    Question: "/api/Lexicon/Question/",
    SendExamMailNotification: "/api/Lexicon/Exam/SendNotification",
    SearchTerm: "/api/Glossary/SearchTerm",
    Exam_User: "/api/Lexicon/Exam/User/",
    Exam_Teacher: "/api/Lexicon/Exam/Teacher/",
    Exam_DeleteUser: "/api/Lexicon/Exam/DeleteUser/",
    Exam_DeleteTeacher: "/api/Lexicon/Exam/DeleteTeacher/",
    Exam_Mark: "/api/Lexicon/Exam/Mark",
    // API endpoints for Class
    Class: "/api/Lexicon/Class",
    AddUserToClass: "/api/Lexicon/Class/AddUserToClass",
    RemoveUserFromClass: "/api/Lexicon/Class/RemoveUserFromClass",
    AssignTopicToClass: "/api/Lexicon/Class/AssignTopicToClass",
    RemoveTopicFromClass: "/api/Lexicon/Class/RemoveTopicFromClass",
    GetTopicFromClass: "/api/Lexicon/Class/GetTopicByClass",
    AssignMeetingToClass: "/api/Lexicon/Class/AssignMeetingToClass",
    RemoveMeetingFromClass: "/api/Lexicon/Class/RemoveMeetingFromClass",
    GetMeetingFromClass: "/api/Lexicon/Class/GetMeetingByClass",
    AssignExamToClass: "/api/Lexicon/Class/AssignExamToClass",
    RemoveExamFromClass: "/api/Lexicon/Class/RemoveExamFromClass",
    GetClassByExam: "/api/Lexicon/Class/GetClassByExam",
    GetUsersFromClass: "/api/Lexicon/Class/GetUsersFromClass",
    AddClassManager: "/api/Lexicon/Class/AddClassManager",
    RemoveClassManager: "/api/Lexicon/Class/RemoveClassManager",
    UpdateClassManager: "/api/Lexicon/Class/UpdateClassManager",
    //
    GetUsers: "/api/User/GetUsers",
    GetAllUsers: "/api/User/GetAllUsers",
    DeleteUnapprovedUser: "/api/Authentication/DeleteUnapprovedUser",
    ApproveUnapprovedUser: "/api/Authentication/ApproveUnapprovedUser",
    GetUnapprovedUsers: "/api/Authentication/GetUnapprovedUsers",
    GetUser: "/api/User/GetUser/",
    DeleteUsers: "/api/User/DeleteUsers",
    AddUser: "/api/Authentication/AddUser",
    UserEmailCheck: "/api/User/EmailCheck",
    GetActiveUser: "/api/User/GetUser/",
    ActivateUser: "/api/Authentication/Activate",
    QuestionSheet: "/api/Lexicon/QuestionSheet",
    GetClasses: "/api/Lexicon/Class/",
    Content: "/api/Lexicon/content/",
    Content_Reorder: "/api/Lexicon/content/Reorder",
    AuthenticationForgotPassword: "/api/Authentication/ForgotPassword",
    AuthenticationResetPassword: "/api/Authentication/UpdatePassword/?userEmail=",
    SendVerificationMail: "/api/Authentication/Admin/SendVerificationMail/?userEmail=",
    GetProvinces: "/api/Authentication/GetProvinces",
    GetDisricts: "/api/Authentication/GetDistricts/?provinceId=",
    GetUserLevels: "/api/Authentication/GetUserLevels",
    GetUserUnits: "/api/Authentication/GetUserUnits",
    GetUserTitles: "/api/Authentication/GetUserTitles",
    Exam_Results: {
      Result: "/api/Lexicon/Exam/Results/",
      MyResult: "/api/Lexicon/Exam/MyResults/",
    },
    Exam_Start: "/api/Lexicon/Exam/Start",
    Exam_Finish: "/api/Lexicon/Exam/Finish",
    Score_Answer: "/api/Lexicon/Exam/Score",
    Topic_User: "/api/Lexicon/Topic/User/",
    Topic_Teacher: "/api/Lexicon/Topic/Teacher/",
    Topic_DeleteUser: "/api/Lexicon/Topic/DeleteUser",
    Topic_DeleteTeacher: "/api/Lexicon/Topic/DeleteTeacher",
    User_Topic: "/api/Lexicon/User/Topic/",
    Teacher_Topic: "/api/Lexicon/Teacher/Topic/",
    User_Exam: "/api/Lexicon/User/Exam/",
    Teacher_Exam: "/api/Lexicon/Teacher/QuestionSheet/",
    User_Meeting: "/api/Lexicon/User/Meeting/",
    Update_Profile: "/api/Authentication/UpdateProfile/",
    Content_For_Course: "/api/Lexicon/ContentForCourse/",
    User_Status: "/api/User/GetUserStatus",
    TimeLineInfo: "/api/Dashboard/GetTimeLineInfos/",
    MyExams: "/api/Lexicon/MyExams/",
    CheckUser: "/api/Authentication/CheckUser",
    UpdateVideoTime: "/api/Lexicon/UpdateVideoTime",
    GetVideoContentUserList: "/api/Lexicon/GetVideoContentUserList",
    GetVideoContent: "/api/Lexicon/GetVideoContent",
    PublishResult: "/api/Lexicon/Exam/PublishResult",
    ShowOrHide: "/api/Lexicon/Exam/ShowOrHideAnswers",
    CheckUserAssignment: "/api/Lexicon/CheckUserAssignment",
  },
  FILE_SHARE_API: {
    GetFolders: "/api/File/GetFolders",
    FileUpload: "/api/File/FileUpload",
    GetFolderFiles: "/api/File/GetFolderFiles",
    FileSearch: "/api/File/FileSearch",
    FileDownload: "/api/File/FileDownload",
    GetUsers: "/api/File/GetUsers",
    FileShare: "/api/File/FileShare",
    GetFiles: "/api/File/GetFiles",
    IsRegisteredUser: "/api/File/IsRegisteredUser",
    Register: "/api/File/Register",
    DeleteFile: "/api/File/DeleteFile",
    GetSharedFiles: "/api/File/GetSharedFiles",
    StopFileSharing: "/api/File/StopFileSharing",
    GetFileSharedUsers: "/api/File/GetFileSharedUsers",
    CreateFolder: "/api/File/CreateFolder",
    DeleteFolder: "/api/File/DeleteFolder",
    StopSharingFileWithMe: "/api/File/StopSharingFileWithMe"
  },
  USER_STATUS: {
    Nothing: 0,
    Passive: 1,
    Active: 2,
    Deleted: 3
  },
  ASSET_STATUS: {
    NOTHING: 0,
    PASSIVE: 1,
    ACTIVE: 2,
    DELETED: 3
  },

  ASSIGNMENT_TYPES: {
    Topics: 'Topics',
    Exams: 'Exams',
    Meetings: 'Meetings',
    Classes: 'Classes',
    Manager_Of_Classes: 'Manager Of Classes',
    Teachers_to_topic: 'TeachersTopic',
    Teachers_to_exam: 'TeachersExam'
  },

  USER_ROLE: {
    All: null,
    Administrator: 1,
    User: 2,
    Teacher: 3,
    _getKeys: function () {
      let keyList = Object.keys(this);
      keyList.pop();
      return keyList;
    }
  },
  EXAM_TYPE: {
    NOTHING: 0,
    EXAM: 1,
    QUIZ: 2
  },
  QUESTION_TYPE: {
    MultipleChoice: 1, // Çoktan Seçmeli
    TrueFalse: 2,      // Doğru Yanlış
    OpenEnded: 3,      // Açık uçlu
    Matching: 4,       // Eşleştirme
  },
  QuestionStrings: [
    { text: i18n.tc("_add_questions._QuestionType.MultipleChoice"), val: 1 },
    { text: i18n.tc("_add_questions._QuestionType.TrueFalse"), val: 2 },
    { text: i18n.tc("_add_questions._QuestionType.OpenEnded"), val: 3 },
    { text: i18n.tc("_add_questions._QuestionType.Matching"), val: 4 },
  ],
  ExamListTypes: [
    { text: i18n.tc("_exam_list.Exam_type1"), val: 1 },
    { text: i18n.tc("_exam_list.Exam_type2"), val: 2 },
    { text: i18n.tc("_exam_list.Exam_type3"), val: 3 },
    { text: i18n.tc("_exam_list.Exam_type4"), val: 4 },
  ],
  URL: {
    // meeting: "https://meet.xperlms.com/"
    meeting: window.location.origin === 'https://lms.akparti.org.tr' ? "https://akonferans.akparti.org.tr/" :"https://meet.xpermeet.com/"
  }
}

Vue.prototype.$enums = ENUMS;
export default ENUMS;
