/**
 * 
 * @param {*} parent goc
 * page common actions 
 */
function PAGE(parent) {
    this.parent = parent;
    this.rootUrl = "";
}

PAGE.prototype = {
    load: function(url) {
        if (typeof url !== "object")
            url = this.rootUrl + url;
        else
            url = url;

        this.parent.app.$router.push(url).catch((error) => {
            this.$goc.console.log(error);
        });
    }
}

export default PAGE;