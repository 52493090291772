<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <v-app app-data>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses"/>
  </div>
  </v-app>
</template>

<script>
  import themeConfig from '@/../themeConfig.js'
  import jwt from "@/http/requests/auth/jwt/index.js"


  export default {
    data() {
      return {
        vueAppClasses: [],
      }
    },
    watch: {
      '$store.state.theme'(val) {
        this.toggleClassInBody(val)
      },
      '$vs.rtl'(val) {
        document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
      }
    },
    methods: {
      toggleClassInBody(className) {
        if (className == 'dark') {
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
          document.body.classList.add('theme-dark')
        } else if (className == 'semi-dark') {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
          document.body.classList.add('theme-semi-dark')
        } else {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        }
      },
      setAppClasses(classesStr) {
        this.vueAppClasses.push(classesStr)
      },
      handleWindowResize() {
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

        // Set --vh property
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      },
      handleScroll() {
        this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
      }
    },
    mounted() {
      this.toggleClassInBody(themeConfig.theme);
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

      // make GENERIC OBJECT CONTAINER as global object
      this.$goc.app = this; // set instance of app to goc app
      window.goc = this.$goc; // make goc global
      this.$moment.locale(this.$i18n.locale);
      window.goc.moment = this.$moment;

      /*
      let accessToken = localStorage.getItem("accessToken");
      let path = router.currentRoute.query.to;
      if (accessToken && path == "/") {
        this.$goc.page.load("/dashboard/analytics");
      }
      */

      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    async created() {

      // jwt
      jwt.init();

      let dir = this.$vs.rtl ? "rtl" : "ltr";
      document.documentElement.setAttribute("dir", dir);

      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('scroll', this.handleScroll);

      // Auth0
      try {
        await this.$auth.renewTokens()
      } catch (e) {
        this.$goc.console.error(e)
      }

    },
    destroyed() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('scroll', this.handleScroll)
    },
  }

</script>
