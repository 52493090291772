const pt_Pt = {
  Dashboard: 'painel de controle',
  eCommerce: 'comércio eletrônico',
  Apps: 'APPS',
  Todo: 'Façam',
  Chat: 'Bate-papo',
  Email: 'O email',
  Calendar: 'Calendário',
  FullCalendar: 'Calendário Completo',
  SimpleCalendar: 'Calendário Simples',
  Shop: 'fazer compras',
  ItemDetails: 'Detalhes do item',
  Cart: 'Carrinho',
  WishList: 'Lista de Desejos',
  Checkout: 'Confira',
  UI: 'UI',
  DataList: 'Lista de dados',
  ListView: 'Exibição de lista',
  ThumbView: 'Thumb View',
  Grid: 'Grade',
  Vuexy: 'Vuexy',
  Vuesax: 'Vuesax',
  Tailwind: 'Tailwind',
  Colors: 'Cores',
  Card: 'Cartão',
  Basic: 'Basic',
  Statistics: 'Estatisticas',
  Analytics: 'Analytics',
  CardActions: 'Ações do Cartão',
  CardColors: 'Cores do cartão',
  Table: 'Mesa',
  agGridTable: 'Tabela AgGrid',
  Components: 'Componentes',
  Alert: 'Alerta',
  Avatar: 'Avatar',
  Badge: 'Crachá',
  Breadcrumb: 'Breadcrumb',
  Button: 'Botão',
  ButtonGroup: 'Grupo de botões',
  Chip: 'Lasca',
  Collapse: 'Colapso',
  Dialogs: 'Diálogos',
  Divider: 'Divisor',
  DropDown: 'Suspenso',
  List: 'Lista',
  Loading: 'Carregando',
  Navbar: 'Navbar',
  Notifications: 'Notificações',
  Pagination: 'Paginação',
  Popup: 'Aparecer',
  Progress: 'Progresso',
  Sidebar: 'Barra Lateral',
  Slider: 'Controle Deslizante',
  Tabs: 'Guias',
  Tooltip: 'dica de ferramenta',
  Upload: 'Envio',
  FormsAndTable: 'Formulários e tabela',
  FormElements: 'Elementos do formulário',
  Select: 'Selecione',
  Switch: 'Interruptor',
  Checkbox: 'Caixa de seleção',
  Radio: 'Rádio',
  Input: 'Entrada',
  NumberInput: 'Entrada numérica',
  Textarea: 'Textarea',
  FormLayouts: 'Layouts de formulário',
  FormWizard: 'Assistente de Formulário',
  FormValidation: 'Validação de Formulário',
  FormInputGroup: 'Grupo de entrada de formulário',
  Pages: 'PÁGINAS',
  Authentication: 'Autenticação',
  Login: 'Entrar',
  Register: 'Registo',
  ForgotPassword: 'Esqueceu A Senha',
  ResetPassword: 'Redefinir Senha',
  LockScreen: 'Tela de bloqueio',
  Miscellaneous: 'Diversos',
  ComingSoon: 'Em breve',
  Error: 'Erro',
  404: '404',
  500: '500',
  NotAuthorized: 'Não autorizado',
  Maintenance: 'Manutenção',
  Profile: 'Perfil',
  User: 'Do utilizador',
  View: 'Visão',
  Edit: 'Editar',
  UserSettings: 'Configurações do Usuário',
  Invoice: 'Fatura',
  FAQ: 'Perguntas frequentes',
  Search: 'Procurar',
  KnowledgeBase: 'Base de Conhecimento',
  ChartsAndMaps: 'Gráficos E Mapas',
  Charts: 'Gráficos',
  ApexCharts: 'Apex Charts',
  chartjs: 'chartjs',
  echarts: 'echarts',
  chartist: 'chartist',
  GoogleMap: 'Google Mapa',
  Extensions: 'Extensão',
  QuillEditor: 'Quill-Editor',
  DragAndDrop: 'Arraste e solte',
  Datepicker: 'Datepicker',
  DatetimePicker: 'Seletor de data e hora',
  AccessControl: 'Controle de acesso',
  I18n: 'I18n',
  Carousel: 'Carrossel',
  Clipboard: 'Prancheta',
  ContextMenu: 'Menu contextual',
  StarRatings: 'Classificações por estrelas',
  Autocomplete: 'autocompletar',
  Tree: 'Árvore',
  // "Import/Export": 'Importar/Exportar',
  Import: 'Importar',
  Export: 'Exportar',
  ExportSelected: "Exportar Selecionado",
  Others: 'Outras',
  MenuLevels: 'Níveis de Menu',
  MenuLevel2p1: 'Nível de menu 2.1',
  MenuLevel2p2: 'Nível de menu 2.2',
  MenuLevel3p1: 'Nível de menu 3.1',
  MenuLevel3p2: 'Nível de menu 3.2',
  DisabledMenu: 'Menu desativado',
  Support: 'Apoio, suporte',
  Documentation: 'Documentação',
  RaiseSupport: 'Levantar Suporte',
  demoTitle: 'Título do cartão',
  demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`,
};

export default pt_Pt;
