// axios
import axios from 'axios'

const domain = process.env.VUE_APP_XPERDRIVE_SERVICE + "/";
console.log({ domain })
const config = {
    baseURL: domain,

};


const axiosFileShare = axios.create(
    config
);

export default axiosFileShare
