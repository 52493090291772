// en, de, fr, pt

import tr_Tr from "./tr_Tr";
import en_En from "./en_En";
import de_De from "./de_De";
import fr_Fr from "./fr_Fr";
import pt_Pt from "./pt_Pt";

export default {
  tr: tr_Tr,
  en: en_En,
  de: de_De,
  fr: fr_Fr,
  pt: pt_Pt
}
