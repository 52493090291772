const en_En = {
  /***********************************/
  _login: {
    E_mail: "E-mail",
    Password: "Password",
    Forgot_Password: "Forgot Password?",
    Register: "Register",
    Login: "Login",
    Remember_Me: "Remember Me",
    Login_Attempt: "Login Attempt",
    Already_logged_in: "You are already logged in!",
    Login_Welcome_Message: "Welcome back, please login to your account."
  },
  /***********************************/
  _forgot_password: {
    Recover_your_password: "Recover your password",
    Recover_Message: "Please enter your email address and we'll send you instructions on how to reset your password.",
    E_mail: "E-mail",
    Back_To_Login: "Back To Login",
    Recover_Password: "Recover Password"
  },
  /***********************************/
  _validations: {
    This_field: "This field",
    is_required: "is required",
    E_mail: "E-mail",
    must_be_valid: "must be valid",
    Value_is: "Value is",
    too_short: "too short.",
    too_long: "too long.",
    too_small: "too small",
    too_big: "too big",
    "File size should be less than": "File size should be less than",
    Value: "Value",
    should_be_between: "should be between",
    and: "and",
  },
  /***********************************/
  _common: {
    Ok: "Ok",
    OK: "OK",
    ok: "ok",
    YES: "YES",
    NO: "NO",
    Delete: "Delete",
    Clear: "Clear",
    Add: "Add",
    Hello: "Hello",
    Edit: "Edit",
    Update: "Update",
    Reset: "Reset",
    Save: "Save",
    Search: "Search",
    Close: "Close",
    Cancel: "Cancel",
    Start: "Start",
    Finish: "Finish",
    Text: "Text",
    Next: "İleri",
    Back: "Geri",
    ToDo: "Todo: Under Construction",
    Chat: "Chat: Under Construction",
    Email: "Email: Under Construction",
    Calendar: "Calendar: Under Construction",
    This_field_is_required: "This field is required",
    of: "of",
    No_Data: "No Data",
    Rows_per_page: "Rows per page",
    Activate_chip: "Activate",
    Activated: "Activated",
    No_data_available: "No data available",
    _popup_days: {
      Last_7_days: "Last 7 days",
      Last_28_days: "Last 28 days",
      Last_Month: "Last Month",
      Last_Year: "Last Year"
    },
    How_can_I_use_this_form: "How can I use this form?",
    Message: 'Message',
    AreYouSure: 'Are you sure?',
    ServiceErrorMessage: "There have been errors in our services.",
    ThereWasProblem: 'There was a problem',
    SentMail: 'Sent Mail',
    Warning: 'Warning',
    Choice: 'Choice',
    Success: "Success",
    Create: "Create",
    Remove: "Remove",
    Esc: "Esc",
    Enter: "Enter",
    Save_Changes: "Save Changes",
    Failed: "Failed",
    AProductOfBITES: 'A Product Of BİTES',
    LanguageOption: 'en',
    Publish: "Publish"
  },
  /***********************************/
  _filterOptions: {
    Filter: 'Filter...',
    Contains: 'Contains',
    NotContains: 'Not Contains',
    Equals: 'Equals',
    NotEqual: 'Not Equal',
    StartsWith: 'Starts With',
    EndsWith: 'Ends With',
    And: 'And',
    Or: 'Or',
    ApplyFilter: 'ApplyFilter'
  },
  /***********************************/
  _backendReponse: {
    ExamFinished: "Exam Finished",
    ExamAssigned: "Exam Assigned",
    TopicAssigned: "Topic Assigned",
  },

  /***********************************/
  _verticalNavbar: {
    Dashboard: 'Dashboard',
    Exam_List: 'My Exams',
    Analytics: 'Analytics',
    Meeting_List: 'My Meetings',
    Course_List: 'My Courses',
    Livestream: 'Live Stream',
    UserManagement: 'User Management',
    UserTransactions: 'User Transactions',
    UserApprove: 'User Approval',
    ClassManagement: 'Class Management',
    Calendar: 'Calendar',
    ExamManagement: 'Exam Management',
    TopicManagement: 'Topic Management',
    MeetingManagement: 'Meeting Management',
    AddQuestions: 'Create Questions',
    AddTopics: 'Create Topics',
    CreateExam: 'Create Exam',
    ExamResults: 'Exam Results',
    CreateContent: 'Create Content',
    AssignExamToUser: "Assign Exam To User",
    AssignExamToTeacher: "Assign Exam To Teacher",
    AssignExamToClass: "Assign Exam To Class",
    AssignTopicToUser: "Assign Topic To User",
    AssignTopicToTeacher: "Assign Topic To Teacher",
    AssignTopicToClass: "Assign Topic To Class",
    UserAssignments: 'User Assignments',
    ClassAssignments: 'Class Assignments',
    Glossary: 'Glossary',
    AddTerms: 'AddTerms',
    FileShare: 'File Share',
    TopicAssignments: 'Topic Assignments',
    ExamAssignments: 'Exam Assignments',
    MeetingAssignments: 'Meeting Assignments',
    MyExamResults: 'My Exam Results',
  },

  /***********************************/
  _analytics: {
    Topic_Count: "Topic Count",
    Term_Count: "Term Count",
    Question_Count: "Question Count",
    Total_Count: "Total Count",
    Go_To_Add_Question: "Go To Add Question",
    _popup_download: {
      Download_SVG: "Download SVG",
      Download_PNG: "Download PNG",
      Download_CSV: "Download CSV"
    },
    Timeline: "Timeline",
    Success_Ratio: "Success Ratio",
    Total_Exam_Count: "Total Exam Count",
    Passed_Exam_Count: "Passed Exam Count",
    Failed_Exam_Count: "Failed Exam Count",
    Unevaluated_exam_count: "Unevaluated Exam Count",
    _video_rate: {
      View_Rate_Video: "Video View Rate",
      User_Name: "User Name",
      Video_Name: "Video Name",
      Video_Percentage: "Video View Rate"
    }
  },

  /***********************************/
  _exam_list: {
    You_can_select_and_start_an_exam: "You can select and start an exam.",
    _info: {
      content: "In this page, you can view your assigned exams."
    },
    No_exams_found: "No exams found.",
    No_exams_found_Text: "You don't have any assigned. Please contact your instructor.",
    ClickToAttendExam: "Click to attend exam",
    Exam_text1: "You are attempting exam",
    Exam_text2: "Exam duration is",
    Exam_text3: "Please press the button to start.",
    Exam_text4: "You have finished exam",
    Exam_text5: "Thanks for your participation.",
    Exam_text6: "Finished questions. Click to finalize your exam.",
    Exam_text7: "You'll be informed when your instructor publish your grades. You can see your grades from 'My Results' page after your grade is published.",
    Exam_type1: "Unsolved Exams",
    Exam_type2: "Solved Exams",
    Exam_type3: "Expired Exams",
    Exam_type4: "All Exams",
    Exams: "Exams",
  },

  /***********************************/
  _meeting_list: {
    Start_Meeting_Text: "You can select and start a meeting.",
    _info: {
      content: "In this page, you can view your assigned meetings."
    },
    No_meetings_found: "No meetings found",
    No_meetings_found_Text: "You don't have any meetings assigned.",
    Meeting_assigned_at: "Meeting starts at:",
    Click_to_attend_meeting: "Click to attend meeting"
  },

  /***********************************/
  _course_list: {
    Course_List: "Course List",
    Attend_Leson_Text: "Select a course and get start your lesson.",
    _info: {
      content: "In this page, you can view your assigned courses and lessons."
    },
    No_course_found: "No course found.",
    No_course_found_Text: "You don't have any course assigned. Please contact your instructor.",
    No_lesson_found: "No lesson found.",
    No_lesson_found_Text: "You don't have any lessons assigned. Please contact your instructor.",
    No_content_added_to_course: "No content has been added to this course."
  },

  /***********************************/
  _attend_list: {
    Attend_Livestream: "Attend Livestream",
    Attend_Leson_Text: "Attend livestream powered by XperMeet",
    _info: {
      content: "In this page, you can watch livestreams powered by XperMeet."
    }
  },


  /***********************************/
  _user_management: {
    User_Management: "User Management",
    User_Approval: "User Approval",
    You_can_manage_your_users: "You can manage your users",
    _info_1: {
      content: "In this page, you can add a user one by one or you can load multiple users with csv file upload in multiple users form section."
    },
    _info_2: {
      title: "Filter Options",
      content: "You can filter user table.",
      Skip_tour: "Skip tour",
      Next: "Next",
    },
    Delete: "Delete",
    Add_User: "Add User",
    Add_Users: "Add User with Excel",
    Search_User: "Search User",
    Approve: "Approve",
    Username: "Username",
    Email: "Email",
    Name: "Name",
    Last_Name: "Surname",
    Role: "Role",
    Status: "Status",
    Identifier: "T.C.",
    Province: "Province",
    District: "District",
    Level: "Level",
    Unit: "Unit",
    Title: "Title",
    Mobile: "Gsm",
    Actions: "Actions",
    Search: "Search",
    Clear: "Clear",
    _popup_Delete: {
      title: "No Selection",
      content: "Please select at least one user to remove"
    },
    _popup_Add_User: {
      title: "Add User",
      content_1: "Add a user manually...",
      First_Name: "First Name",
      Last_Name: "Last Name",
      User_Name: "User Name",
      Email: "Email",
      Password: "Password",
      User_Role: "User Role",
      _popup_Role: {
        User: "User",
        Administrator: "Administrator",
        Teacher: "Teacher"
      },
      User_Status: "User Status",
      _popup_Status: {
        Active: "Active",
        Passive: "Passive",
        Deleted: "Deleted"
      },
      Send_confirmation_Text: "Send confirmation email to user?",
      content_2: "Add multiple users from file",
      Add_users_Text: "Add users via an excel file",
      TrySelectingFile: "Try selecting a file",
      AddExcelErrorMessage: "Excel can't be uploaded, please check your excel file and try again.",
      UserSuccessfullyCreated: "Users created successfully.",
      SuccessfullyCreated: "Successfully created.",
      EmailConfirmationSent: "Email confirmation sent.",
      Hover: {
        Create_a_user: "Create a user"
      },
      UserExists: "User already exists in system."
    },
    Subtitle: 'You can manage your users.',
    Subtitle2: 'You can approve your users.',
    Hover: {
      ResendActivation: 'Resend Activation',
      ResetPassword: 'Reset Password'
    },
    DeleteUser: 'Delete User',
    UserRemovedSuccessfuly: 'User removed successfuly',
    UserApprovedSuccessfuly: 'User approved successfuly',
    UserPasswordResetActivationLink: "User password reset activation link send to user email address",
    UserPasswordResetLink: "User password reset link send to user email address",
    user_edit: {
      Account: "Account",
      Information: "Information",
      Social: "Social",
      Reset_Avatar: "Reset Avatar",
      User_Name: "User Name",
      Name: "Name",
      Last_Name: "Last Name",
      Email: "Email"
    },
    Restricted_Operation: "Restricted Operation",
    You_can_not_delete_yourself: "You can not delete yourself...",
    Selected_record_will_be_removed: " selected record(s) will be removed...",
    Selected_record_will_be_approved: "selected record(s) will be approved...",
    No_Selections: "No Selections",
    Please_select_at_least_one_user_to_remove: "Please select at least one user to remove",
    Confirm_Delete: "Confirm Delete",
    You_are_about_to_delete: "You are about to delete",
    Tour_Filter_Options: "Filter Options",
    Tour_Filter_Options_Content: "You can filter user table.",
    Tour_User_List: "User List",
    Tour_User_List_Content: "List of LMS users.",
    Tour_Add_New_User: "Add New User",
    Tour_Add_New_User_Content: "You can add user by clicking this button."
  },

  /***********************************/
  _user_profile: {
    Account: {
      SuccessMessage: 'Profile is edited.'
    }
  },

  /***********************************/
  _notification: {
    NotificationMail: "Notification Mail",
    NotificationSentSuccessfully: "Notifications sent successfully..."
  },

  /***********************************/
  _exam_management: {
    Exam_Management: "Calendar",
    Subtitle: 'You can manage your exams and meetings.',
    You_can_manage_your_exams: "You can manage your exams",
    Edit_Event: "Edit Event",
    _info: {
      content: "In this page, you can view or update your assigned meetings and exams.",
    },
    Exam: "Exam",
    Meeting: "Meeting",
    Event_Title: "Event Title",
    _add_meeting: {
      title: "Add Meeting",
      Event_Title: "Event Title",
      Start_Date_Time: "Start Date & Time",
      Duration: "Duration",
      Add_Meeting: "Add Meeting",
      Update_Meeting: 'Update Meeting',
      DoYouWantDeleteMeeting: "Do you want to delete meeting?",
      Meeting_Created: "Meeting created.",
      Go_to_meeting: "Go to meeting",
      Meeting_Updated: "Meeting Updated",
      Meeting_Deleted: "Meeting deleted.",
    },
    Month: "Month",
    Week: "Week",
    Year: "Year",
    Hours: "h",
    Minutes: "min",
    Seconds: "sec",
    Upcoming: "Upcoming Exam",
    Ongoing: "Ongoing Exam",
    Expired: "Expired Exam",
    Disabled: "Disabled Exam",
    ExamNotification: {
      SheetUpdated: 'Sheet updated',
      YouCantUpdateExam: "You can not modify this exam. It has been used by some users."
    },
    DoYouWantSendEmail: "Do you want to send Email notification to attendees?",
    DoYouWantToLeaveExamPage: "Do you want to leave exam page?",
    Start_Date: "Start Date",
    End_Date: "End Date",
    Active: "Active",
    Start_Date_And_Time: "Start Date & Time",
    Duration: "Duration"
  },
  /***********************************/
  _add_questions: {
    Question: "Question",
    Add_Questions: "Add Questions",
    Add_a_Questions: "Add a Question",
    You_can_add_questions: "You can add questions",
    Search_Topic: "Search Topic",
    Enter_questions: "Enter your question here",
    Enter_one_ore_more: "Enter one or more choices for the question: (Check for correct answer(s))",
    Choice: "Choice",
    Question_Score: "Question Score",
    Value_is_too_short: "Value is too short",
    _info: {
      content: "In this page, you can add questions to selected topics.",
    },
    Subtitle: "You can add questions",
    PleaseEnter15Characters: "Please enter more than 15 characters to your question.",
    PleaseSelectTopic: "Please select a topic.",
    Please_enter_a_question_text_or_an_image: "Please enter a question text or an image",
    You_have_to_add_more_than_one_choice: "You have to add more than one choice!",
    Question_successfully_added: "Question successfully added.",
    You_must_select_only_one_correct_answer: "You must select only one correct answer",
    You_must_fill_the_question: "You must fill the question.",
    Select_at_least_one_topic: "Select at least one topic",
    True: "True",
    False: "False",
    _QuestionType: {
      MultipleChoice: "Multiple Choice",
      TrueFalse: "True/False",
      OpenEnded: "Open Ended",
      Matching: "Matching"
    }

  },

  /***********************************/

  _add_topics: {
    Add_Topics: "Add Topics",
    Add_Topics_Text: "You can add topic for questions",
    Search_Topic: "Search Topic",
    Enter_a_topic_name: "Enter a topic name",
    Enter_a_description: "Enter a description",
    Select_a_Image: "Select a Image",
    Topic_successfully_added: "Topic successfully added.",
    Topic_successfully_updated: "Topic successfully updated.",
    Topic_successfully_deleted: "Topic successfully deleted.",
    Warning: "Warning Try entering a topic name",
    _info: {
      content: "In this page, you can create topics, modules and lessons.",
    },
    Selected_topic_is_going_to_be_deleted: "Selected topic is going to be deleted.",
    Selected_topic_is_going_to_be_updated: "Selected topic is going to be updated."
  },
  /***********************************/
  _add_classes: {
    Add_Classes: "Add Classes",
    Add_Classes_Text: "You can add, update or delete classes",
    Search_Class: "Search Class",
    Enter_a_class_name: "Enter a Class name",
    Class_successfully_added: "Class successfully added.",
    Class_successfully_updated: "Class successfully updated.",
    Class_successfully_deleted: "Class successfully deleted.",
    Warning: "Try entering a class name",
    _info: {
      title: "How can I use this form?",
      content: "In this page, you can create, delete or update classes.",
    },
    Selected_class_is_going_to_be_deleted: "Selected Class is going to be deleted.",
    Selected_class_is_going_to_be_updated: "Selected Class is going to be updated."
  },
  /***********************************/
  _create_exam: {
    Create_Exam: "Create Exam",
    Select_a_topic: "1. Select a topic",
    Filters_the_questions: "Filter the questions",
    Please_select_questions: "2. Please select questions",
    For_question_sheet: "For question sheet",
    Exam_name_and_Duration: "3. Exam name and Duration",
    You_can_preview_Text: "You can preview the exam below",
    Search_Topic: "Search Topic",
    Search_for_questions: "Search for questions",
    Topic: "Topic",
    Question_Text: "Question_Text",
    CreateAQuestionSheet: "Create a question sheet",
    Point: "Point",
    Enter_your_exam_name: "Enter your exam name",
    Activation_Date: "Activation Date",
    Expiration_Date: "Expiration Date",
    StartTime: "Start Time",
    EndTime: "End Time",
    Enter_Duration_for_exam: "Enter Duration for exam",
    Randomize_questions: "Randomize questions",
    _info: {
      content: "In this page, you can can create quizzes or examinations.",
    },
    Information: "Information",
    Topic_not_found: "Topic not found. Please click here to add topic",
    Select_question_amount: "Select question amount",
    Select_at_least_one_topic_to_load_questions: "Select at least one topic to load questions",
    Created_sheet: "Created sheet",
    Exam_Preview: "4. Exam Preview",
    Duration_must_be_greater_than_zero: "Duration must be greater than zero",
    Please_check_your_duration: "Please check your duration",
    Enter_Success_Rate: "Threshold of Success(%)"
  },

  /***********************************/
  _exam_results: {
    Exam_Results: "Exam Results",
    Exam_Results_Text: "Filter exams and_user to get exam results.",
    Exam_filter: "Exam filter",
    User_filter: "User filter",
    Class_filter: "Class filter",
    Exam_Name: "Exam Name",
    User: "User",
    Date: "Date",
    Score: "Score",
    Point: "Point",
    Active: "Active",
    Correct_Answers_For_Question: "Correct Answers For This Question",
    Mark_Success: "Exam activated successfully.",
    User_Answers_For_Question: "User Answers For This Question",
    _info: {
      content: "In this page, you can filter exams by users or sheets, check answers and scores.",
    },
    Time_spent: "Time spent"
  },

  /***********************************/
  _create_content: {

    Create_Content: "Create Content",
    Add_New_Content: "Add New Content",
    Create_topic_contents: "Create topic contents.",
    Search_Topic: "Search Topic",
    Add_content_Text: "Add content to selected topic",
    Add_a_content: "Add a content",
    Update_content_Text: "Update Content",
    Please_select_a_Text: "Please select a topic from Topic List.",
    Please_enter_a_name: "Please enter a name",
    Please_enter_a_description: "Please enter a description",
    Video: "Video",
    HTML: "HTML",
    Unreal: "WebGL",
    Exam: "Exam",
    Pdf: "Pdf",
    Drive: "Drive",
    Move: "Move",
    Name: "Name",
    Description: "Description",
    Content_Type: "Content Type",
    Actions: "Actions",
    Please_select_Content_Type: "Please select Content Type",
    Content_is_created: "Content is created",
    Please_select_a_topic: "Please select a topic",
    Please_select_content_type: "Please select content type",
    Please_enter_HTML_content: "Please enter HTML content",
    Please_select_a_file: "Please select a file",
    Reordered: "Reordered",
    New_Subtitle: "New Subtitle",
    Edit_Subtitle: "Edit Subtitle",
    Language: "Language",
    File: "File",
    _video: {
      Video_Content: "Video Content",
      Is_this_text: "Is this video 360 degrees?",
      Disable_Video_Controls: "Disable Video Controls",
      Please_Select_text: "Please Select Text",
      Please_select_a_video: "Please select a video",
      Add_Subtitle: "Add Subtitle",
      _add_subtitle: {
        New_Subtitle: "New Subtitle",
        Language: "Language",
        Please_Select: "Please Select",
      },
      Language: "Language",
      File: "File",
      Action: "Action",
      Click_to_add_Video_Content: "Click to add Video Content",
    },


    _html: {
      Html_Content: "Html Content",
      Click_to_add_Html_Content: "Click to add Html Content",
    },
    _webgl: {
      WebGL_Content: "WebGL Content",
      Please_select_a_zip_file: "Please select a zip file",
      Click_to_add_WebGL_Content: "Click to add WebGL Content",
    },
    _exam: {
      Exam_Content: "Exam Content",
      Select_an_exam_sheet: "Select an exam sheet",
      Click_to_add_exam_Content: "Click to add exam Content",
      Exam_started_at: "Exam starts at:",
      Exam_finished_at: "Exam finishes at:"
    },
    _pdf: {
      Pdf_Content: "Pdf Content",
      Please_Select_Text: "Please select a pdf file",
      Click_to_add_Pdf_Content: "Click to add Pdf Content",

    },
    _drive: {
      Drive_Content: "XperDrive Content",
      Please_Select_Text: "Please select a XperDrive file",
      Click_to_add_Drive_Content: "Click to add Drive Content",
    },
    _info: {
      content: "In this page, you can create and add contents for your courses",
    },
    _file_upload_error: "Your files must be located the root directory in your zip file, do not use nested file format."
  },

  /***********************************/
  _user_assignments: {
    User_Assignments: "User Assignments",
    Teacher_Assignments: "Teacher Assignments",
    Class_Management: "Class Management",
    User_Management_Text: "You can enroll students in this page.",
    Teacher_Management_Text: "You can enroll teachers in this page.",
    Please_Select: "Please Select",
    Search_Directory: "Search Directory",
    Available_users: "Available users",
    Search: "Search",
    Name: "Name",
    Successfully_removed_user_from_exam: "Successfully removed user from exam.",
    Successfully_removed_user_from_meeting: "Successfully removed user from meeting.",
    Successfully_removed_user_from_class: "Successfully removed user from class.",
    Successfully_removed_user_from_topic: "Successfully removed user from topic.",
    Successfully_removed_teacher_from_topic: "Successfully removed teacher from topic.",
    Successfully_added_user_to_exam: "Successfully added user to exam.",
    Successfully_added_user_to_meeting: "Successfully added user to meeting.",
    Successfully_added_user_to_class: "Successfully added user to class.",
    Successfully_added_user_to_topic: "Successfully added user to topic.",
    Successfully_added_teacher_to_topic: "Successfully added teacher to topic.",
    YouCantUnassignUser: "There is a class assingment for selected user(s), you can not unassigned it.",
    Users_in_class: "Assigned Users",
    No_matching_trainees_found: "No matching trainees found.",
    No_trainees_found: "No trainees found.",
    _info: {
      content: "In this page, you can list all users and assigned users by selecting related point from the left side. Please select users and click the green arrow icon to assign them.",
    },
    Topics: "Topics",
    Exams: "Exams",
    Meetings: "Meetings",
    Classes: "Classes",
    Manager_of_classes: "Manager Of Classes"
  },


  /***********************************/
  _class_assignments: {
    Class: "Class",
    Class_Assignments: "Class Assignments",
    Class_Management: "Class Management",
    Class_Management_Text: "You can enroll classes in this page.",
    Please_Select: "Please Select",
    Search_Directory: "Search Directory",
    Available_classes: "Available Classes",
    Search: "Search",
    Name: "Name",
    Successfully_removed_class_from_topic: "Class removed class from topic.",
    Successfully_removed_class_from_meeting: "Successfully removed class from meeting.",
    Successfully_removed_class_from_exam: "Successfully removed class from exam.",
    Successfully_added_class_to_topic: "Successfully added class to topic.",
    Successfully_added_class_to_meeting: "Successfully added class to meeting.",
    Successfully_added_class_to_exam: "Successfully added class to exam.",
    Assignment_in_Class: "Assigned Classes",
    No_matching_trainees_found: "No matching trainees found.",
    No_trainees_found: "No trainees found.",
    _info: {
      content: "In this page, you can list all classes and assigned classes by selecting related point from the left side. Please select classes and click the green arrow icon to assign them.",
    },
  },

  /***********************************/
  _glossary: {
    Glossary: "Glossary",
    View_flashcards: "View flashcards",
    Search_Topic: "Search Topic",
    Search_Word: "Search Word",
    Term_was_not_found: "Term was not found.",
    Words: "Words",
    _info: {
      content: "In this page, you can view flashcards for your courses.",
    },
    Termcard_header_front: "Do you know?",
    Termcard_header_back: "Answer",
    Termcard_footer_front: "Click to show Back",
    Termcard_footer_back: "Click to show Front"
  },

  /***********************************/
  _add_terms: {
    Add_Terms: "AddTerms",
    Create_a_term: "Create a term",
    Add_flashcards: "Add flashcards.",
    Search_Topic: "Search Topic",
    Terms_deleted_successfuly: "Terms deleted successfuly",
    Successfully_created: "Terms successfully created",
    Please_select_a_topic: "Please select a topic",
    Term_was_not_found: "Term was not found.",
    Text: "Text",
    Value: "Value",
    Search_Word: "Search Word",
    Words: "Words",
    Actions: "Actions",
    _info: {
      content: "In this page, you can view flashcards for your courses.",
    },
  },
  /***********************************/
  _file_share: {
    File_Share: "File Share",
    File_sharing: "File sharing",
    Register: "Register",
    _info: {
      Password: "Password",
      content: "In this page, you can attend exams and solve questions.",
      Register: "Register"
    },
    Add_New_Foler: "Add New Folder",
    Selected_Folder: "Selected Folder",
    Create_Folder: "Create Folder",
    File_Upload: "File Upload",
    Upload: "Upload",
    Add_Folder: "Add Folder",
    Stop_File_Sharing: "Stop File Sharing",
    Share_Files: "Share Files",
    Available_users: "Available users",
    _content1: "Because of your document security reasons, You must define a password to use this feature.",
    _content2: "You can share and download documents with using this password.",
    _content3: "Please write your password somewhere else, we do not keep your password in our systems.",
    _content4: "If you loose your password, you can't reach your files again and can not be recovered.",
    Please_enter_your_password: "Please enter your password",
    Folder_Name: "Folder Name",
    Please_select_a_file: "Please select a file",
    Search_Folder: "Search Folder",
    Search_File: "Search File",
    Download: "Download",
    Share: "Share",
    Stop_sharing_this_file_with_me: "Stop sharing this file with me",
    Shared_Users: "Shared Users",
    Stop_File_Share: "Stop File Share",
    Share_File: "Share Files",
    Search_User: "Search User",
    Enter_Password: "Enter Password",
    Confirm_Password: "Confirm Password",
    Password: "Password",
    Shared: "Shared",
    File_Name: "File Name",
    Size: "Size",
    Actions: "Actions",
    User_Name: "User Name",
    File_Sharing_Stopped: "File Sharing Stopped",
    File_successfully_shared: "File successfully shared",
    File_was_not_found: "File was not found",
    No_Data: "No Data",
    Selected_file_will_be_removed_from_you: "Selected file will be removed from you",
    File_successfuly_removed_from_you: "File successfuly removed from you",
    Folder_successfuly_created: "Folder successfuly created",
    Selected_folder_will_be_removed: "Selected folder will be removed",
    Folder_successfuly_deleted: "Folder successfuly deleted",
    File_successfuly_uploaded: "File successfuly uploaded",
    Selected_file_will_be_removed: "Selected file will be removed",
    File_successfuly_deleted: "File successfuly deleted"
  },
  /***********************************/
  _edit_profile: {
    Profile: "Profile",
    Edit_Profile: "Edit Profile",
    Subtitle: "You can edit to your profile information.",
    First_Name: "First Name",
    Last_Name: "Last Name",
    User_Name: "User Name",
    EMail: "E-mail",
    Clear: "Clear",
    Save: "Save",
    Profile_is_edited: "Profile is edited.",
    Tour_Change_Profile_Picture: "Change Profile Picture",
    Tour_Change_Profile_Picture_Content: "You can change your profile image.",
    Tour_Not_Allowed: "Not allowed",
    Tour_Not_Allowed_Content: "You are not allowed to edit username.",
    Tour_Save: "Save",
    Tour_Save_Content: "You can save the changes."
  },

  /***********************************/
  _tour: {
    Previous: "Previous",
    Next: "Next",
    Skip: "Skip",
    Finish: "Finish"
  },

  /***********************************/
  _profile_dropdown: {
    Available: "Available",
    Profile: "Profile",
    Logout: "Logout"
  },

  /***********************************/
  _notification_menu: {
    New: "New",
    App_Notifications: "App Notifications",
    New_Exam: "New Exam",
    You_have_a_new_exam_on: "You have a new exam on",
    New_meeting_assigned: "New Meeting Assigned",
    You_got_new_meeting_on: "You got new meeting on",
    Expiration_alert: "Expiration Alert",
    Your_exam_will_be_expired_on: "Your exam will be expired on",
    View_all_notifications: "View All Notifications"
  },
  /***********************************/
  _score_answer: {
    Score_Answers: "Score Answers",
    Score_Answers_Text: "You can score open ended question answers in exams from this page.",
    Score: "Score",
    Score_Tooltip: "Score answer",
    Success_Message: "Successfully scored",
    Publish_Success: "Exam Result successfully published",
  },
  /***********************************/
  _register: {
    Create_Account: "Create Account",
    Subtitle: "Fill the below form to create a new account",
    First_name: "First Name",
    Last_name: "Last Name",
    User_name: "User Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    Accept_terms_conditions: "I accept the terms & conditions.",
    Login: "Login",
    Register: "Register",
    You_are_already_logged_in: "You are already logged in!",
    Login_Attempt: "Login Attempt",
    message: "Please check your email to confirm your registration.",
    User_already_exists: "User already exists!",
    Please_check_your_Email_or_Username: "Please check your Email or Username."
  }

};

export default en_En;
