// axios
import axios from 'axios'

const domain = process.env.VUE_APP_ORCHESTRATOR_SERVICE + "/";

const configNoTimeout = {
    baseURL: domain
};


const axiosConfigNoTimeout = axios.create(
    configNoTimeout
    // You can add your headers here
);

export default axiosConfigNoTimeout;
