/**
 *
 * @param {*} parent
 * ortak kullanılacak static request alanları tanımlama
 * this.$goc.
 */

import i18n from '@/i18n/i18n.js'

function COMMON_REQUESTS(parent) {
  this.parent = parent;
  this.API = parent.parent.ENUMS.API;
}

COMMON_REQUESTS.prototype = {
  /**
   *
   * @param {*} callback
   * callback function returs array og role list
   */
  getRoles: function (callback) {
    let result = [];
    this.parent.get(this.API.GetRoleTypes, (response) => {
      if (response.IsSuccess) {
        result = [{ text: i18n.tc("_user_management._popup_Add_User._popup_Role.User"), value: 2 }, { text: i18n.tc("_user_management._popup_Add_User._popup_Role.Administrator"), value: 1 }, { text: i18n.tc("_user_management._popup_Add_User._popup_Role.Teacher"), value: 3 }];
        // result = response.Data;
      }
      if (callback && typeof callback === "function") {
        callback(result);
      } else {
        return result;
      }
    });
  },

  getUserStatus: function (callback) {
    let result = [];
    this.parent.get(this.API.User_Status, (response) => {
      if (response.IsSuccess) {
        // result = JSON.parse(JSON.stringify(response));
        result = [{ text: i18n.tc('_user_management._popup_Add_User._popup_Status.Active'), value: 2 }, { text: i18n.tc('_user_management._popup_Add_User._popup_Status.Passive'), value: 1 }];
        // result = result.Data;
      }
      if (callback && typeof callback === "function") {
        callback(result);
      } else {
        return result;
      }
    });
  }
}

export default COMMON_REQUESTS;
