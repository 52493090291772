import NOTIFICATIONS from "./notifications.js";

function UTILS(parent) {
    this.parent = parent;
    this.notifications = new NOTIFICATIONS(parent);
}

UTILS.prototype = {
    getParent: function() {
        return this.parent;
    }
}

UTILS.prototype.tree = function() {
    /** Search element in a tree  */

    return {
        result: null,
        search: function(treeList, key, searchVal) {
            let j = 0;
            let length = treeList.length;

            for (j; j < length; j++) {
                if (treeList[j][key] == searchVal) {
                    this.result = treeList[j];
                } else if (treeList[j].Children != null) {
                    this.search(treeList[j].Children, key, searchVal);
                }
            }
            return this.result;
        }
    }

}

export default UTILS;