import Vue from 'vue';
import PAGE from "./page.js";
import UTILS from "./utils/utils.js";
import ERROR_HANDLER from "./errorHandler.js";
import LMS_REQUEST from "./lmsRequest.js";
import ENUMS from "./enums.js";
import FORM_VALIDATIONS from "./form_validation.js";
import NOTIFICATIONS from "./utils/notifications.js";
import CONSOLE from "./console.js";
import LANGUAGE_HELPER from "./languageHelper";

/**
 * Generic Object Container
 * golabally reach pages and vue actions
 */
function GOC() {
    this.app = null;
    this.ENUMS = ENUMS; // global enums
    this.modules = {}; // set and get modules if modules registred
    this.page = new PAGE(this); // page functions for common page actions Ex: page.load(url);
    this.utils = new UTILS(this); // helper classes
    this.errorHandler = new ERROR_HANDLER(this); // error handler for server side err responses
    this.validate = new FORM_VALIDATIONS(this);
    this.request = new LMS_REQUEST(this); // request for all lms
    this.notify = new NOTIFICATIONS(this);
    this.langHelper = new LANGUAGE_HELPER(this);
    this.container = {};
    /**
     * this => goc element
     * true => open or close messages come from anywhere
     */
    this.console = new CONSOLE(this, true); // console.log this print console logs to window screen with goc.notify.success
}

GOC.prototype = {
    set: function(key, val) {
        this.container[key] = val;
    },
    get: function(key) {
        return this.container[key];
    },
    setModule: function(moduleName, modulex) {
        this.modules[moduleName] = modulex;
    },
    getModule: function(moduleName) {
        return this.modules[moduleName];
    }
}
let goc = new GOC();

Vue.prototype.$goc = goc;
