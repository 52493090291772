/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import NotAuthorized from './views/pages/NotAuthorized'

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'video.js/dist/video-js.min.css';
import 'vuesax/dist/vuesax.css'; // Vuesax
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";
Vue.use(Vuesax);

// axios
import axios from "./axios.js"

Vue.prototype.$http = axios;

// axios
import axiosNoTimeout from "./axiosNoTimeout.js"

// API Calls
import "./http/requests"

// mock
import "./fake-db/index.js"

// Theme Configurations
import '../themeConfig.js'


// Firebase
import '@/firebase/firebaseConfig'


// Auth0 Plugin
// import AuthPlugin from "./plugins/auth";

// Vue.use(AuthPlugin);


// ACL
// import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);


// Tour
import VueTour from 'vue-tour'

Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');


// VeeValidate
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);


// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'YOUR_API_KEY',
    libraries: 'places', // This is required if you use the Auto complete plug-in
  },
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'

Vue.use(VueHammer);


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css');


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/tr';

Vue.use(CKEditor);

import VueFriendlyIframe from 'vue-friendly-iframe';

Vue.use(VueFriendlyIframe);


/* notification */
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css';
Vue.use(VueIziToast);

Vue.config.productionTip = false;
import vuetify from './plugins/vuetify.js'

// https://www.npmjs.com/package/vue-cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

//https://www.npmjs.com/package/vue-moment
const moment = require('moment')

Vue.use(require('vue-moment'), {
  moment
})

// goc
import "./goc/goc.js";

const createVueInstance = () => {
  const acl = require('./acl/acl');
  new Vue({
    router,
    store,
    i18n,
    acl,
    vuetify,
    axiosNoTimeout,
    render: h => h(App),
    watch: {
      "$keycloak.token": {
        handler: function (newToken) {
          // Api.setToken(newToken);
          // this.api.defaults.headers.common = {
          //   Authorization: `bearer ${token}`,
          // };
          localStorage.setItem("keycloak-token", newToken);
        }
      }
    }
  }).$mount('#app');
}

Vue.use(VueKeycloakJs, {
  init: {
    onLoad: "login-required",
    checkLoginIframe: false
  },
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM
  },
  // eslint-disable-next-line no-unused-vars

  onReady: keycloak => {
    localStorage.setItem("keycloak-token", keycloak.token);
    localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);
    localStorage.setItem("accessToken", keycloak.token);

    axios.get('/api/Authentication/CheckUser', {
      headers: {
        'Content-Type': 'message/x-protobuf',
        "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + keycloak.token
      }
    })
      .then((response) => {
        if (response) {
          const user = response.data.Result;
          if (user) {
            user.userRole = user.UserRoles;
            localStorage.setItem("userInfo", JSON.stringify(user));
            store.commit("UPDATE_USER_INFO", user);
            console.log("başarılı")

            createVueInstance();
          }
          else {
            console.error("user yoook")
          }
        }
      })
      .catch((e) => {
        localStorage.removeItem("userInfo");
        console.log("CheckUser error", e)
        createVueInstance();
      });
  }
});



