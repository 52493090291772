function FORM_VALIDATIONS(parent) {
    this.parent = parent;
}

FORM_VALIDATIONS.prototype = {
    create: function(label) {
        let validate = new Array();
        let me = this;
        validate.__proto__ = Object.assign(validate.__proto__, {
            pushValidation: function(validation) {
                this.push(validation);
                return this;
            },
            required: function() {
                let validation = v => !!v || `${label || me.parent.app.$t("_validations.This_field")} ${me.parent.app.$t("_validations.is_required")}`;
                return this.pushValidation(validation);
            },
            email: function() {
                let validation = v => /.+@.+\..+/.test(v) || `${label || me.parent.app.$t("_validations.E_mail")} ${me.parent.app.$t("_validations.must_be_valid")}`;
                return this.pushValidation(validation);
            },
            min: function(minVal) {
                let validation = v => (v && v.length >= minVal) || `${label || me.parent.app.$t("_validations.Value_is")} ${me.parent.app.$t("_validations.too_short")}`;
                return this.pushValidation(validation);
            },
            max: function(maxVal) {
                let validation = v => (v && v.length <= maxVal) || `${label || me.parent.app.$t("_validations.Value_is")} ${me.parent.app.$t("_validations.too_long")}`;
                return this.pushValidation(validation);
            },
            file: function(byte_size) {
                let validation = value => !value || value.size < byte_size || `${me.parent.app.$t("_validations.File size should be less than")} ${byte_size} KB`;
                return this.pushValidation(validation);
            },
            checkVal: function(min, max) {
                let validation = v => v >= min && v <= max || `${label || me.parent.app.$t("_validations.Value")} ${me.parent.app.$t("_validations.should_be_between")} ${min} ${me.parent.app.$t("_validations.and")} ${max}`;
                return this.pushValidation(validation)
            },
            gt: function(minVal) {
                let validation = v => (v && v >= minVal) || `${label || me.parent.app.$t("_validations.Value_is")} ${me.parent.app.$t("_validations.too_small")}`;
                return this.pushValidation(validation);
            },
            lt: function(minVal) {
                let validation = v => (v && v <= minVal) || `${label || me.parent.app.$t("_validations.Value_is")} ${me.parent.app.$t("_validations.too_big")}`;
                return this.pushValidation(validation);
            },
        });
        return validate;
    },
    clear: function() {
        return [];
    }
}

export default FORM_VALIDATIONS;
