/**
 * 
 * @param {*} parent parent element goc
 * @param {*} active Boolean true, false
 * 
 * Usage;
 * 
 * this.$goc.console.< log, info, warn, error >(<*data>, <*key>)
 * 
 * *data = data which will be printed
 * *key = name of printed data to seperate log data from keys
 */
function CONSOLE(parent, active) {
    this.parent = parent;
    this.active = active;
    this.maxHistorySize = 50;
    this.history = [];
    this.statistics = {
        counts: {
            log: 0,
            error: 0,
            warn: 0
        }
    };
}

CONSOLE.prototype = {

    getHistorySize: function() {
        return Object.keys(this.history).length;
    },
    printLog: function(type, data, key) {
        if (this.active) {

            let logTime = new Date().toJSON();
            let historySize = this.getHistorySize();

            this.statistics.counts[type]++;
            let logKey = key ? key : '';

            if (historySize >= this.maxHistorySize) {
                this.history.shift();
                this.history.push({ type: type, key: logKey, time: logTime, data: data });
            } else {
                this.history.push({ type: type, key: logKey, time: logTime, data: data });
            }
            // this.parent.notify.success({ title: "CONSOLE LOG", message: JSON.stringify(data) });
            // console[type](data);
        }
    },
    log: function(data, key) {
        this.printLog("log", data, key);
    },
    error: function(data, key) {
        this.printLog("error", data, key);
    },
    warn: function(data, key) {
        this.printLog("warn", data, key);
    },
    info: function(data, key) {
        this.printLog("info", data, key);
    }

}

export default CONSOLE;