import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller);


let userInfo = JSON.parse(localStorage.getItem("userInfo"));
let initialRole = userInfo ? userInfo.userRole : "None";

export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    Administrator: new AclRule("LmsAdministrator").generate(),
    Teacher: new AclRule("LmsTeacher").or("LmsAdministrator").generate(),
    User: new AclRule("LmsUser").or("LmsAdministrator").or("LmsTeacher").generate(),
    None: new AclRule("None").or("User").or("LmsAdministrator").or("LmsTeacher").generate()
  }
})
