import axiosFileShare from "../axiosFileShare.js";
import noTimoutAxiosVideoRequest from '../axiosNoTimeout.js';
import axios from "@/axios.js";
import COMMON_REQUESTS from "./commonRequest.js";

function LMS_REQUEST(parent) {
    this.parent = parent;
    this.common = new COMMON_REQUESTS(this);
}

LMS_REQUEST.prototype = {
    _getMultipartHeader: function() {
        let multipartHeader = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "*/*",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + localStorage.getItem("keycloak-token")
            }
        };
        return multipartHeader;
    },
    _getHeader: function() {
      let header = {
            headers: {
                'Content-Type': 'message/x-protobuf',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + localStorage.getItem("keycloak-token")
            }
        };
        return header;
    },
    _getRestHeader : function(){
      let header = {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + localStorage.getItem("keycloak-token")
        }
      };
      return header;
    },

    get(url, callback, params, disableLoading) {
        let header = this._getHeader();
        if (params) {
            header['params'] = params;
        }
        this.returnPromise(axios.get(url, header), callback, disableLoading);
    },
    post(url, body, callback, disableLoading,message=null) {
        this.returnPromise(axios.post(url, body, this._getHeader()), callback, disableLoading,message);
    },
    postVideo(url, body, callback, options = {}) {
        this.returnPromise(noTimoutAxiosVideoRequest.post(url, body, { ...options, ...this._getMultipartHeader() }), callback, !!options.onUploadProgress);
    },
    postMultipart(url, body, callback, options = {}) {
        this.returnPromise(noTimoutAxiosVideoRequest.post(url, body, { ...options, ...this._getMultipartHeader() }), callback, !!options.onUploadProgress);
    },
    delete(url, callback, params) {
        let header = this._getHeader();
        if (params) {
            header['data'] = params;
        }
        this.returnPromise(axios.delete(url, header), callback);
    },
    put(url, body, callback) {
        this.returnPromise(axios.put(url, body, this._getHeader()), callback);
    },
    patch(url, body, callback) {
        this.returnPromise(axios.patch(url, body, this._getHeader()), callback);
    },
    getFileShare(url, callback, params) {
      let header = this._getHeader();
      if (params) {
        header['params'] = params;
      }
      this.returnPromise(axiosFileShare.get(url, header), callback);
    },
    postFileShare(url, body, callback) {
      this.returnPromise(axiosFileShare.post(url, body, this._getRestHeader()), callback);
    },
    postFileShareMultipart(url, body, callback, options = {}) {
      this.returnPromise(axiosFileShare.post(url, body, options, this._getMultipartHeader()), callback, !!options.onUploadProgress);
    },
    // putFileShareMultipart(url,body,callback){
    //   this.returnPromise(axiosFileShare.put(url, body, this._getMultipartHeader()), callback);
    // },
    /**
     *
     * @param {*} request http request
     * @param {*} callback function or object ex:
     *  Function usage: ()=>{}
     *  Promise Usage:
     *  {
     *      then: (resultData) => {...},
     *      exception: (exception) => {...},
     *      final: (empty) => {...},
     *  }
     */
    returnPromise(request, callback, disableLoading,errorMsg = null) {
        let promise = null;
        let { then, exception, final } = callback || {};

        if (!disableLoading) {
          this.parent.app.$vs.loading();
        }

        if (callback && typeof callback == 'function') {
            promise = callback;
        }
        // server original result returns
        request.then((result) => {

                // original results checked by errorHandler
                this.parent.errorHandler.check(result);
                this.parent.app.$vs.loading.close();
                if (promise) {
                    promise(result.data); // returns data of original Result
                } else if (then) {
                    then(result.data); // returns data of original Result
                }

                let elements = document.querySelector("lang");
            })
            .catch((exceptionMessage) => {
                let jsonMessage = exceptionMessage;
                this.parent.app.$vs.loading.close();
                if (exception) {
                    exception(exceptionMessage);
                } else {
                    if (jsonMessage == undefined) {
                        jsonMessage = { data: { HttpStatusCode: "502" } };
                    }
                    this.parent.errorHandler.check(jsonMessage,errorMsg);
                }
            })
            .finally(() => {
                if (final) {
                    final();
                }
            });
    }
}

export default LMS_REQUEST;
