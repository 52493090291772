import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

export default {
    init() {
        axios.interceptors.response.use(function(response) {
            return response
        }, (error) => {

          // const { config, response: { status } } = error
          const {config, response} = error;
          const originalRequest = config;

          // if (status === 401) {
          if (response && response.status === 401) {

            if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true;
              store.dispatch("auth/fetchAccessToken")
                .then((access_token) => {
                  isAlreadyFetchingAccessToken = false;
                  onAccessTokenFetched(access_token)
                })
            }

            let promise = new Promise((resolve) => {
              addSubscriber(access_token => {
                originalRequest.headers.Authorization = 'Bearer ' + access_token;
                resolve(axios(originalRequest))
              })
            });
            return promise;

          }
          if ((response && response.status > 400) || !response) {
            return Promise.reject(response);
          } else {
            return Promise.resolve(response);
          }
        })
    },
  login(email, pwd) {
    return axios.post("/api/authentication/login", {email: email, password: pwd})
  },
  registerUser(firstName, lastName, name, email, pwd, sendEmail) {
    return axios.post("/api/authentication/", {
      firstName: firstName,
      lastName: lastName,
      userName: name,
      email: email,
      password: pwd,
      SendEmail: sendEmail
    })
  },
  refreshToken() {
    return axios.post("/api/authentication/refresh-token", {accessToken: localStorage.getItem("accessToKen")})
  }
}
