
const tr_Tr = {
  /***********************************/
  _login: {
    E_mail: "E-posta",
    Password: "Şifre",
    Forgot_Password: "Şifremi Unuttum?",
    Register: "Kayıt",
    Login: "Giriş",
    Remember_Me: "Beni Hatırla",
    Login_Attempt: "Giriş Denemesi",
    Already_logged_in: "Daha önceden giriş yapılmış!",
    Login_Welcome_Message: "Tekrar merhaba, lütfen kayıtlı kullanıcı adnız ile giriş yapınız."
  },
  /***********************************/
  _forgot_password: {
    Recover_your_password: "Şifrenizi kurtarın",
    Recover_Message: "Lütfen e-posta adresinizi giriniz. E-posta adresinize şifrenizi yenilemek için gerekli yönergeleri göndereceğiz.",
    E_mail: "E-posta",
    Back_To_Login: "Giriş Ekranı",
    Recover_Password: "Şifremi Kurtar"
  },
  /***********************************/
  _validations: {
    This_field: "Bu alan",
    is_required: "zorunludur.",
    E_mail: "E-posta",
    must_be_valid: "geçerli olmalı.",
    Value_is: "Bu değer",
    too_short: "çok kısa.",
    too_long: "çok uzun.",
    too_small: "çok küçük",
    too_big: "çok büyük",
    "File size should be less than": "Olması gereken dosya boyutu",
    Value: "Değer",
    should_be_between: "arasında",
    and: "ve",
  },
  /***********************************/
  _backendReponse: {
    ExamFinished: "Sınav Bitti",
    ExamAssigned: "Sınav Atandı",
    TopicAssigned: "Kurs Atanadı"
  },
  /***********************************/
  _common: {
    Ok: "Tamam",
    OK: "TAMAM",
    ok: "tamam",
    YES: "EVET",
    NO: "HAYIR",
    Delete: 'Sil',
    Clear: "Temizle",
    Add: "Ekle",
    Hello: "Merhaba",
    Edit: 'Düzenle',
    Update: "Güncelle",
    Reset: "Sıfırla",
    Save: "Kaydet",
    Search: "Araştır",
    Close: "Kapat",
    Cancel: "İptal",
    Start: "Başla",
    Finish: "Bitir",
    Text: "Metin",
    Next: "İleri",
    Back: "Geri",
    ToDo: "Todo: Yapım Aşamasında",
    Chat: "Chat: Yapım Aşamasında",
    Email: "Email: Yapım Aşamasında",
    Calendar: "Takvim: Yapım Aşamasında",
    This_field_is_required: "Gerekli Alan",
    of: "/",
    No_Data: "Veri Yok",
    Rows_per_page: "Sayfa Başı Satır Sayısı",
    Activate_chip: "Etkinleştir",
    Activated: "Etkin",
    No_data_available: "Kullanılabilir Veri Yok",
    _popup_days: {
      Last_7_days: "Son 7 gün",
      Last_28_days: "Son 28 gün",
      Last_Month: "Son 1 Ay",
      Last_Year: "Son 1 Yıl"
    },
    How_can_I_use_this_form: "Bu formu nasıl kullanabilirim?",
    Message: 'Mesaj',
    AreYouSure: 'Emin misiniz?',
    ServiceErrorMessage: "Servis Hatası",
    ThereWasProblem: 'Hata',
    SentMail: 'Mail Gönder',
    Warning: 'Uyarı',
    Choice: 'Seç',
    Success: "Başarılı",
    Create: "Oluştur",
    Remove: "Kaldır",
    Esc: "Sil",
    Enter: "Ekle",
    Save_Changes: "Değişiklikleri Kaydet",
    Failed: "Başarısız",
    AProductOfBITES: 'BİTES Ürünüdür.',
    LanguageOption: "tr",
    Publish: "Tüm Sınav Bilgileri Yayınla",
    ShowExamResult: "Cevapları Göster",
    HideExamResult: "Cevapları Gizle"
  },
  /***********************************/
  _filterOptions: {
    Filter: 'Filtrele...',
    Contains: 'İçerir',
    NotContains: 'İçermez ',
    Equals: 'Eşittir',
    NotEqual: 'Eşit Değildir',
    StartsWith: 'İle Başlar',
    EndsWith: 'İle Biter',
    And: 've',
    Or: 'veya',
    ApplyFilter: 'Filtre Uygula'
  },
  /***********************************/
  _verticalNavbar: {
    Dashboard: 'Gösterge Paneli',
    Exam_List: 'Sınavlarım',
    Analytics: 'Analizler',
    Meeting_List: 'Toplantılarım',
    Course_List: 'Kurslarım',
    Livestream: 'Canlı Yayın',
    UserManagement: 'Kullanıcı Yönetimi',
    UserTransactions: 'Kullanıcı İşlemleri',
    UserApprove: 'Kullanıcı Onaylama',
    ClassManagement: 'Sınıf Yönetimi',
    Calendar: 'Takvim',
    ExamManagement: 'Sınav Yönetimi',
    TopicManagement: 'Konu Yönetimi',
    MeetingManagement: 'Toplantı Yönetimi',
    AddQuestions: 'Soru Oluştur',
    AddTopics: 'Konu Oluştur',
    CreateExam: 'Sınav Oluştur',
    ExamResults: 'Sınav Sonuçları',
    CreateContent: 'İçerik Oluştur',
    AssignExamToUser: "Sınavı Kullanıcıya Ata",
    AssignExamToTeacher: "Sınavı Öğretmene Ata",
    AssignExamToClass: "Sınavı Sınıfa Ata",
    AssignTopicToUser: "Konuyu Kullanıcıya Ata",
    AssignTopicToTeacher: "Konuyu Öğretmene Ata",
    AssignTopicToClass: "Konuyu Sınıfa Ata",
    UserAssignments: 'Kullanıcıya Ata',
    ClassAssignments: 'Sınıfa Ata',
    Glossary: 'Terimler',
    AddTerms: 'Terim Ekle',
    FileShare: 'Dosya Paylaşımı',
    TopicAssignments: 'Konu Atamaları',
    ExamAssignments: 'Sınav Atamaları',
    MeetingAssignments: 'Toplantı Atamaları',
    MyExamResults: 'Sınav Sonuçlarım',
  },
  /***********************************/
  _analytics: {
    Topic_Count: "Toplam Konu Sayısı",
    Term_Count: "Toplam Terim Sayısı",
    Question_Count: "Toplam Soru Sayısı",
    Total_Count: "Toplam Sayı",
    Go_To_Add_Question: "Soru Eklemeye Git",
    _popup_download: {
      Download_SVG: "İndir SVG",
      Download_PNG: "İndir PNG",
      Download_CSV: "İndir CSV"
    },
    Timeline: "Zaman Çizelgesi",
    Success_Ratio: "Başarı Oranı",
    Total_Exam_Count: "Toplam Sınav Sayısı",
    Passed_Exam_Count: "Başarılı Sınav Sayısı",
    Failed_Exam_Count: "Başarısız Sınav Sayısı",
    Unevaluated_exam_count: "Değerlendirilmemiş Sınav Sayısı",
    _video_rate: {
      View_Rate_Video: "Video Eğitimlerinin İzlenme Oranı",
      User_Name: "Kullanıcı Ad Soyad",
      Video_Name: "Video Adı",
      Video_Percentage: "Video Görüntülenme Oranı"
    }
  },
  /***********************************/
  _exam_list: {
    You_can_select_and_start_an_exam: "Bir sınav seçip başlatabilirsiniz.",
    _info: {
      content: "Bu sayfada, size atanan sınavları görebilirsiniz."
    },
    No_exams_found: "Sınav verisi bulunamadı.",
    No_exams_found_Text: "Atanmış sınavınız yok. Lütfen eğitmeninize başvurun.",
    ClickToAttendExam: "Sınava katılmak için tıklayınız.",
    Exam_text1: "Ulaşmak istediğiniz sınav",
    Exam_text2: "Sınav süresi",
    Exam_text3: "Lütfen başlamak için butona tıklayınız.",
    Exam_text4: "Bitirmiş olduğunuz sınav",
    Exam_text5: "Katılımınız için teşekkürler.",
    Exam_text6: "Sorularınızı bitirdiniz. Cevaplarınızı kontrol edebilir veya sınavınızı bitirmek için butona basabilirsiniz.",
    Exam_text7: "Sınav sonuçlarınız eğitmen tarafından yayınlandığı zaman bilgilendirileceksiniz. Yayınlandıktan sonra 'Sınav Sonuçlarım' sayfasından görüntüleyebilirsiniz.",
    Exam_type1: "Çözülmemiş Sınavlar",
    Exam_type2: "Çözülmüş Sınavlar",
    Exam_type3: "Süresi Dolmuş Sınavlar",
    Exam_type4: "Tüm Sınavlar",
    Exams: "Sınavları",
  },


  /***********************************/
  _meeting_list: {
    Start_Meeting_Text: "Bir toplantı seçip başlatabilirsiniz.",
    _info: {
      content: "Bu sayfada, size atanan toplantıları görebilirsiniz."
    },
    No_meetings_found: "Toplantı Bulunamadı",
    No_meetings_found_Text: "Herhangi bir toplantı atanmamış.",
    Meeting_assigned_at: "Toplantının başlangıç tarihi:",
    Click_to_attend_meeting: "Toplantıya katılmak için tıklayınız."
  },
  /***********************************/
  _course_list: {
    Course_List: "Kurs Listesi",
    Attend_Leson_Text: "Bir kurs seçin ve derslere başlayın.",
    _info: {
      content: "Bu sayfada sana tanımlanmış kursları ve dersleri görüntüleyebilirsin."
    },
    No_course_found: "Kurs bulunamadı.",
    No_course_found_Text: "Kurs atanmamış.",
    No_lesson_found: "Ders yok",
    No_lesson_found_Text: "Ders atanmamış.",
    No_content_added_to_course: "Bu kursa eklenmiş bir içerik bulunmamaktadır."
  },
  /***********************************/
  _attend_list: {
    Attend_Livestream: "Atanmış Canlı Yayın",
    Attend_Leson_Text: "XperMeet tarafından desteklenen canlı yayına katılın",
    _info: {
      content: "Bu sayfada, XperMeet tarafından desteklenen canlı akışları izleyebilirsiniz."
    }
  },


  /***********************************/
  _user_management: {
    You_can_not_approve_yourself: "Kendi kullanıcınızı onaylayamazsınız.",
    User_Management: "Kullanıcı Yönetimi",
    User_Approval: "Kullanıcı Onaylama",
    You_can_manage_your_users: "Kullanıcılarını yönetebilirsin.",
    _info_1: {
      content: "Bu sayfada, bir kullanıcıyı tek tek ekleyebilir veya birden çok kullanıcıyı csv dosyası yüklemesi ile birden çok kullanıcı formu bölümüne yükleyebilirsiniz."
    },
    _info_2: {
      title: "Filtre Seçeneği",
      content: "Kullanıcı tablosunu filtreleyebilirsin.",
      Skip_tour: "Turu atla",
      Next: "Sonraki",
    },
    Delete: "Sil",
    Add_User: "Kullanıcı Ekle",
    Add_Users: "Excel İle Ekle",
    Search_User: "Kullanıcı Ara",
    Approve: "Onayla",
    Username: "Kullanıcı Adı",
    Email: "Email",
    Name: "Ad",
    Last_Name: "Soyad",
    Role: "Rol",
    Status: "Durum",
    Identifier: "T.C. Kimlik No",
    Province: "İl",
    District: "İlçe",
    Level: "Kademe",
    Unit: "Birim",
    Title: "Ünvan",
    Mobile: "Gsm",
    Actions: "İşlemler",
    Search: "Ara",
    Clear: "Temizle",
    _popup_Delete: {
      title: "Seçim yok",
      content: "Lütfen kaldırılacak en az bir kullanıcı seçin"
    },
    _popup_Add_User: {
      title: "Kullanıcı Ekle",
      content_1: "Manuel olarak bir kullanıcı ekleyin...",
      First_Name: "Ad",
      Last_Name: "Soyad",
      User_Name: "Kullanıcı adı",
      Email: "Email",
      Password: "Şifre",
      User_Role: "Kullanıcı Rolü",
      _popup_Role: {
        User: "Kullanıcı",
        Administrator: "Yönetici",
        Teacher: "Öğretmen"
      },
      User_Status: "Kullanıcı Durumu",
      _popup_Status: {
        Active: "Aktif",
        Passive: "Pasif",
        Deleted: "Silinmiş"
      },
      Send_confirmation_Text: "Kullanıcıya onay e-postası gönderilsin mi?",
      content_2: "Dosyadan birden fazla kullanıcı ekle",
      Add_users_Text: "Excel dosyasıyla kullanıcı ekle",
      TrySelectingFile: "Dosyayı yüklemeyi tekrar deneyin.",
      AddExcelErrorMessage: "Excel yüklenirken hata oluştu, lütfen exceli kontrol ettikten sonra tekrar deneyin.",
      UserSuccessfullyCreated: "Kullanıcılar başarıyla oluşturuldu.",
      SuccessfullyCreated: "Başarıyla oluşturuldu",
      EmailConfirmationSent: "Eposta onaylaması gönderildi.",
      Hover: {
        Create_a_user: "Kullanıcı oluştur"
      },
      UserExists: "Eklenilmek istenilen kullanıcı sistemde kayıtlıdır."
    },
    Subtitle: 'Kullanıcılarınızı yönetebilirsiniz.',
    Subtitle2: 'Kullanıcılarınızı onaylayabilirsiniz.',
    Hover: {
      ResendActivation: 'Tekrar Aktif Et',
      ResetPassword: 'Parolayı Sıfırla'
    },
    DeleteUser: 'Kullanıcıyı sil',
    UserRemovedSuccessfuly: '"Kullanıcı başarıyla silindi.',
    UserApprovedSuccessfuly: '"Kullanıcı başarıyla onaylandı.',
    UserPasswordResetActivationLink: "Kullanıcı şifre sıfırlama aktivasyon linki kullanıcının posta adresine gönderildi.",
    VerificationMailSended: "Verifikasyon maili kullanıcının posta adresine gönderildi.",
    UserPasswordResetLink: "Kullanıcı şifre sıfırlama linki kullanıcının posta adresine gönderildi.",
    user_edit: {
      Account: "Hesap",
      Information: "Bilgi",
      Social: "Sosyal",
      Reset_Avatar: "Avatarı sıfırla",
      User_Name: "Kullanıcı adı",
      Name: "Ad",
      Last_Name: "Soyad",
      Email: "Eposta"
    },
    Selected_record_will_be_removed: " seçili kayıtlar silinecek...",
    Selected_record_will_be_approved: " seçili kayıtlar onaylanacak...",
    Restricted_Operation: "Geçersiz işlem",
    You_can_not_delete_yourself: "Kendi kullanıcınızı silemezsiniz...",
    No_Selections: "Kullanıcı seçiniz",
    Please_select_at_least_one_user_to_remove: "Lütfen en az bir kullanıcı seçiniz",
    Confirm_Delete: "Silme Onay",
    You_are_about_to_delete: "Silmek üzeresiniz",
    Tour_User_List: "Kullanıcı Listesi",
    Tour_User_List_Content: "LMS kullanıcılarını bu tablodan filtreleyebilirsiniz.",
    Tour_Add_New_User: "Yeni Kullanıcı Ekle",
    Tour_Add_New_User_Content: "Bu düğmeye basarak yeni kullanıcı ekleyebilirsiniz."
  },
  /***********************************/
  _user_profile: {
    Account: {
      SuccessMessage: 'Profil düzenlendi.'
    }
  },
  /***********************************/
  _notification: {
    NotificationMail: "Bildirim postası",
    NotificationSentSuccessfully: "Bildirimler başarıyla gönderildi..."
  },
  /***********************************/
  _exam_management: {
    Exam_Management: "Takvim",
    Subtitle: 'Sınavlarınızı ve toplantılarınızı yönetebilirsiniz.',
    You_can_manage_your_exams: "Sınavlarınızı yönetebilirsiniz",
    Edit_Event: "Olay Düzenle",
    _info: {
      content: "Bu sayfada sınav ve toplantılarınızı görüntüleyebilir veya güncelleyebilirsiniz. ",
    },
    Exam: "Sınav",
    Meeting: "Toplantı",
    Event_Title: "Etkinlik Başlığı",
    _add_meeting: {
      title: "Toplantı Ekle",
      Event_Title: "Etkinlik Adı",
      Start_Date_Time: "Başlangıç Tarihi & Saati",
      Duration: "Süre",
      Add_Meeting: "Toplantı Ekle",
      Update_Meeting: 'Toplantı Güncelle',
      DoYouWantDeleteMeeting: "Toplantıyı silmek istiyor musunuz?",
      Meeting_Created: "Toplantı oluşturuldu.",
      Go_to_meeting: "Toplantıya git",
      Meeting_Updated: "Toplantı güncellendi.",
      Meeting_Deleted: "Toplantı silindi."
    },
    Month: "Ay",
    Week: "Hafta",
    Year: "s",
    Hours: "h",
    Minutes: "dk",
    Seconds: "sn",
    Upcoming: "Yakın Sınav",
    Ongoing: "Devam Eden Sınav",
    Expired: "Süresi Dolmuş Sınav",
    Disabled: "Devre Dışı Sınav",
    ExamNotification: {
      SheetUpdated: 'Sınav güncellendi',
      YouCantUpdateExam: "Bu sınav bazı kullanıcılar tarafından çözüldüğü için değişiklik yapılamaz."
    },
    DoYouWantSendEmail: "Katılımcılara Eposta bildirimi göndermek istiyor musunuz?",
    DoYouWantToLeaveExamPage: "Sınav sayfasından ayrılmak istiyor musunuz?",
    Start_Date: "Başlama tarihi",
    End_Date: "Bitiş tarihi",
    Active: "Aktif",
    Start_Date_And_Time: "Başlangıç Tar. & Saat",
    Duration: "Süre"
  },
  /***********************************/
  _add_questions: {
    Question: "Soru",
    Add_Questions: "Soru Ekle",
    Add_a_Questions: "Soru Ekle",
    You_can_add_questions: "Soru ekleyebilirsiniz",
    Search_Topic: "Konu Ara",
    Enter_questions: "Sorunuzu buraya girebilirsiniz",
    Enter_one_ore_more: "Soru için bir veya daha fazla seçenek girin: (Doğru cevapları kontrol ediniz)",
    Choice: "Seçenek",
    Question_Score: "Soru Puanı",
    Value_is_too_short: "Girilen değer çok kısa",
    _info: {
      content: "Bu sayfada, seçilen konulara sorular ekleyebilirsiniz.",
    },
    Subtitle: "Soru ekleyebilirsiniz",
    PleaseEnter15Characters: "Soru oluşturmak için 15'ten fazla karakter giriniz.",
    PleaseSelectTopic: "Lütfen konu seçiniz.",
    Please_enter_a_question_text_or_an_image: "Lütfen soru cümlenizi veya ilgili fotoğrafınızı ekleyiniz.",
    You_have_to_add_more_than_one_choice: "Birden fazla seçenek eklemelisiniz!",
    Question_successfully_added: "Soru başarıyla eklendi.",
    You_must_select_only_one_correct_answer: "Sadece bir doğru cevap seçebilirsiniz.",
    You_must_fill_the_question: "Soruyu doldurmalısınız.",
    Select_at_least_one_topic: "En az bir konu seçmelisiniz.",
    True: "Doğru",
    False: "Yanlış",
    _QuestionType: {
      MultipleChoice: "Çoktan Seçmeli",
      TrueFalse: "Doğru/Yanlış",
      OpenEnded: "Açık Uçlu",
      Matching: "Eşleştirme"
    }

  },

  /***********************************/

  _add_topics: {
    Add_Topics: "Konu Ekle",
    Add_Topics_Text: "Sorular için konu ekleyebilirsiniz",
    Search_Topic: "Konu Ara",
    Enter_a_topic_name: "Konu adı gir",
    Enter_a_description: "Tanım Gir",
    Select_a_Image: "Görsel Seç",
    Topic_successfully_added: "Konu başarıyla eklendi.",
    Topic_successfully_updated: "Konu başarıyla güncellendi.",
    Topic_successfully_deleted: "Konu başarıyla silindi.",
    Warning: "Uyarı Bir konu adı girmeyi deneyin",
    _info: {
      content: "Bu sayfada, konular, modüller ve dersler oluşturabilirsiniz.",
    },
    Selected_topic_is_going_to_be_deleted: "Seçilen konu silinecek.",
    Selected_topic_is_going_to_be_updated: "Seçilen konu güncellenecek.",
  },
  /***********************************/
  _add_classes: {
    Add_Classes: "Sınıf Ekle / Düzenle",
    Add_Classes_Text: "Sınıfları Ekleyebilir veya Güncelleyebilirsiniz.",
    Search_Class: "Sınıf Ara",
    Enter_a_class_name: "Sınıf adı gir",
    Class_successfully_added: "Sınıf başarıyla eklendi.",
    Class_successfully_updated: "Sınıf başarıyla güncellendi.",
    Class_successfully_deleted: "Sınıf başarıyla silindi.",
    Warning: "Uyarı Bir Sınıf adı girmeyi deneyin",
    _info: {
      title: "Bu Form'u Nasıl Kullanırım?",
      content: "Bu sayfada sınıfları oluşturabilir, silebilir veya adını güncelleyebilirsiniz.",
    },
    Selected_class_is_going_to_be_deleted: "Seçilen Sınıf silinecek.",
    Selected_class_is_going_to_be_updated: "Seçilen Sınıf güncellenecek.",
  },
  /***********************************/
  _create_exam: {
    Create_Exam: "Sınav Oluştur",
    Select_a_topic: "1. Konu Seç",
    Filters_the_questions: "Soruları filtrele",
    Please_select_questions: "2. Lütfen Soru Seçiniz",
    For_question_sheet: "Soru sayfası İçin",
    Exam_name_and_Duration: "3. Sınav ve Süre",
    You_can_preview_Text: "Aşağıdan sınav önizlemesi yapabilirsiniz",
    Search_Topic: "Konu ara",
    Search_for_questions: "Soru ara",
    Topic: "Konu",
    Question_Text: "Soru İçeriği",
    CreateAQuestionSheet: "Sınav oluştur",
    Point: "Puan",
    Enter_your_exam_name: "Sınav Adı",
    Activation_Date: "Başlangıç Tarihi",
    Expiration_Date: "Bitiş Tarihi",
    StartTime: "Başlangıç Saati",
    EndTime: "Bitiş Saati",
    Enter_Duration_for_exam: "Sınav için süre giriniz",
    Randomize_questions: "Rastgele soru seç",
    _info: {
      content: "Bu sayfada, test veya sınavlar oluşturabilirsiniz.",
    },
    Information: "Bilgilendirme",
    Topic_not_found: "Konu bulunamadı. Konu eklemek için lütfen tıklayınız.",
    Select_question_amount: "Soru sayısı seçiniz.",
    Select_at_least_one_topic_to_load_questions: "Soruların yüklenmesi için en az bir konu seçiniz.",
    Created_sheet: "Sınav oluşturuldu",
    Exam_Preview: "4. Sınav Önizleme",
    Duration_must_be_greater_than_zero: "Süre 0'dan büyük olmalı.",
    Please_check_your_duration: "Lütfen süreyi kontrol ediniz.",
    Enter_Success_Rate: "Başarılı Sınırı(%)"
  },

  /***********************************/
  _exam_results: {
    Exam_Results: "Sınav Sonuçları",
    Exam_Results_Text: "Sınav sonuçlarını almak için sınavları ve kullanıcıyı filtreleyin.",
    Exam_filter: "Sınav Filtresi",
    User_filter: "Kullanıcı Filtresi",
    Class_filter: "Sınıf Filtresi",
    Exam_Name: "Sınav Adı",
    User: "Kullanıcı",
    Date: "Tarih",
    Score: "Skor",
    Point: "Puan",
    Active: "Etkin",
    Correct_Answers_For_Question: "Sorunun Doğru Cevapları",
    Mark_Success: "Bu sınav başarıyla etkinleştirildi.",
    User_Answers_For_Question: "Kullanıcının Cevapları",
    _info: {
      content: "Bu sayfada, sınavları kullanıcılara veya sayfalara göre filtreleyebilir, cevapları ve puanları kontrol edebilirsiniz.",
    },
    Time_spent: "Harcanan süre"
  },

  /***********************************/
  _create_content: {

    Create_Content: "İçerik Oluştur",
    Add_New_Content: "Yeni İçerik Ekle",
    Create_topic_contents: "İçerik oluşturabilirsiniz.",
    Search_Topic: "Konu Bul",
    Add_content_Text: "Seçili konuya içerik ekle",
    Add_a_content: "İçerik ekle",
    Update_content_Text: "İçeriği güncelle",
    Please_select_a_Text: "Lütfen Konu Listesinden bir konu seçin.",
    Please_enter_a_name: "Lütfen bir ad girin",
    Please_enter_a_description: "Lütfen açıklama girin",
    Video: "Video",
    HTML: "HTML",
    Unreal: "WebGL",
    Exam: "Sınav",
    Pdf: "Pdf",
    Drive: "Drive",
    Move: "Taşı",
    Name: "Ad",
    Description: "Açıklama",
    Content_Type: "İçerik Türü",
    Actions: "İşlemler",
    Please_select_Content_Type: "Lütfen içerik türü seçiniz.",
    Content_is_created: "İçerik oluşturuldu.",
    Please_select_a_topic: "Lütfen konu seçiniz.",
    Please_select_content_type: "Lütfen içerik türü seçiniz.",
    Please_enter_HTML_content: "HTML içeriği giriniz.",
    Please_select_a_file: "Lütfen dosya seçin",
    Reordered: "Tekrar sıralandı.",
    New_Subtitle: "Yeni Altyazı",
    Edit_Subtitle: "Altyazı düzenle",
    Language: "Dil",
    File: "Doküman",
    _video: {
      Video_Content: "Video İçeriği",
      Is_this_text: "Bu video 360 derece mi?",
      Disable_Video_Controls: "Video Kontrollerini Devre Dışı Bırak",
      Please_Select_text: "Lütfen Metin Seçiniz",
      Please_select_a_video: "Lütfen video seçiniz",
      Add_Subtitle: "Alt Yazı Ekle",
      _add_subtitle: {
        New_Subtitle: "Yeni Alt Başlık",
        Language: "Dil",
        Please_Select: "Lütfen Seçiniz",
      },
      Language: "Dil",
      File: "Dosya",
      Action: "İşlem",
      Click_to_add_Video_Content: "Video içeriği eklemek için tıklayınız.",
    },


    _html: {
      Html_Content: "Html İçeriği",
      Click_to_add_Html_Content: "HTML içeriği eklemek için tıklayınız",
    },
    _webgl: {
      WebGL_Content: "WebGL İçeriği",
      Please_select_a_zip_file: "Lütfen bir zip dosyası seçin",
      Click_to_add_WebGL_Content: "WebGL içeriği eklemek için tıklayınız",
    },
    _exam: {
      Exam_Content: "Sınav İçeriği",
      Select_an_exam_sheet: "Lütfen bir sınav dosyası seçin",
      Click_to_add_exam_Content: "Sınav içeriği eklemek için tıklayınız",
      Exam_started_at: "Sınav Başlangıç Tarihi:",
      Exam_finished_at: "Sınav Bitiş Tarihi:"

    },
    _pdf: {
      Pdf_Content: "Pdf İçeriği",
      Please_Select_Text: "Lütfen bir pdf dosyası seçin",
      Click_to_add_Pdf_Content: "Pdf içeriği eklemek için tıklayınız",
    },
    _drive: {
      Drive_Content: "XperDrive İçeriği",
      Please_Select_Text: "Lütfen bir XperDrive dosyası seçin",
      Click_to_add_Drive_Content: "XperDrive içeriği eklemek için tıklayınız",
    },
    _info: {
      content: "Bu sayfada, kurslarınız için içerik oluşturabilir ve ekleyebilirsiniz",
    },
    _file_upload_error: "Dosyalarınızın zip dosyasında ana dizinde olması gerekmektedir, zip dosyasında başka bir klasör içinde dosyaları yükleyemezsiniz"
  },

  /***********************************/
  _user_assignments: {
    User_Assignments: "Kullanıcı Atamaları",
    Teacher_Assignments: "Öğretmen Atamaları",
    Class_Management: "Sınıf Yönetimi",
    User_Management_Text: "Öğrencileri bu sayfada kaydedebilirsiniz.",
    Teacher_Management_Text: "Öğretmenleri bu sayfada kaydedebilirsiniz.",
    Please_Select: "Lütfen Seçiniz",
    Search_Directory: "Dizinde Ara",
    Available_users: "Mevcut kullanıcılar",
    Search: "Arama",
    Name: "Ad",
    Successfully_removed_user_from_exam: "Kullanıcı ataması sınavdan kaldırıldı.",
    Successfully_removed_user_from_meeting: "Kullanıcı ataması toplantıdan kaldırıldı.",
    Successfully_removed_user_from_class: "Kullanıcı ataması sınıftan kaldırıldı.",
    Successfully_removed_user_from_topic: "Kullanıcı ataması konudan kaldırıldı.",
    Successfully_removed_teacher_from_topic: "Öğretmen ataması konudan kaldırıldı.",
    Successfully_added_user_to_exam: "Kullanıcı sınava başarıyla eklendi.",
    Successfully_added_user_to_meeting: "Kullanıcı toplantıya başarıyla eklendi.",
    Successfully_added_user_to_class: "Kullanıcı sınıfa başarıyla eklendi.",
    Successfully_added_user_to_topic: "Kullanıcı konuya başarıyla eklendi.",
    Successfully_added_teacher_to_topic: "Öğretmen konuya başarıyla eklendi.",
    No_matching_trainees_found: "Eşleşen katılımcı bulunamadı.",
    YouCantUnassignUser: "Seçili kullanıcı(lar) sınıf üzerinden atandığı için, ilgili kullanıcılara dair atamalar kaldırılamadı.",
    No_trainees_found: "Katılımcı bulunamadı.",
    Users_in_class: "Atanan Kullanıcılar",
    _info: {
      content: "Bu sayfada, Sol taraftan ilgili yeri seçerek tüm kullanıcıları ve atanmış kullanıcıları görüntüleyebilirsiniz. İstediğiniz kullanıcıları seçip yeşil ok işaretine tıklayarak atamalarınızı gerçekleştirebilirsiiz.",
    },
    Topics: "Konular",
    Exams: "Sınavlar",
    Meetings: "Toplantılar",
    Classes: "Sınıflar",
    Manager_of_classes: "Sınıf Yöneticisi"
  },
  /***********************************/
  _class_assignments: {
    Class: "Sınıf",
    Class_Assignments: "Sınıf Atamaları",
    Class_Management: "Sınıf Yönetimi",
    Class_Management_Text: "Sınıfları bu sayfada kaydedebilirsiniz.",
    Please_Select: "Lütfen Seçiniz",
    Search_Directory: "Dizinde Ara",
    Available_classes: "Mevcut Sınıflar",
    Search: "Arama",
    Name: "Ad",
    Successfully_removed_class_from_topic: "Sınıf ataması konudan kaldırıldı.",
    Successfully_removed_class_from_meeting: "Sınıf ataması toplantıdan kaldırıldı.",
    Successfully_removed_class_from_exam: "Sınıf ataması sınavdan kaldırıldı.",
    Successfully_added_class_to_topic: "Sınıf başarıyla konuya eklendi.",
    Successfully_added_class_to_meeting: "Sınıf başarıyla toplantıya eklendi.",
    Successfully_added_class_to_exam: "Sınıf başarıyla sınava eklendi.",
    Assignment_in_Class: "Atanan Sınıflar",
    No_matching_trainees_found: "Eşleşen sınıf bulunamadı.",
    No_trainees_found: "Sınıf bulunamadı.",
    _info: {
      content: "Bu sayfada, Sol taraftan ilgili yeri seçerek tüm sınıfları ve atanmış sınıfları görüntüleyebilirsiniz. İstediğiniz sınıfları seçip yeşil ok işaretine tıklayarak atamalarınızı gerçekleştirebilirsiiz.",
    },
  },
  /***********************************/
  _glossary: {
    Glossary: "Sözlük",
    View_flashcards: "",
    Search_Topic: "Konu Ara",
    Search_Word: "Kelime ara",
    Term_was_not_found: "Kriter bulunamadı.",
    Words: "Kelimeler",
    _info: {
      content: "Bu sayfada, kurslarınız için bilgi kartlarını görüntüleyebilirsiniz.",
    },
    Termcard_header_front: "Biliyor musun?",
    Termcard_header_back: "Cevap",
    Termcard_footer_front: "Arka yüzü görmek için tıkla",
    Termcard_footer_back: "Ön yüzü görmek için tıkla"
  },

  /***********************************/
  _add_terms: {
    Add_Terms: "Terim Ekle",
    Create_a_term: "Terim oluştur",
    Add_flashcards: "Bilgi Kartı Ekle",
    Search_Topic: "Konu Ara",
    Terms_deleted_successfuly: "Terimler başarıyla silindi",
    Successfully_created: "Terim başarıyla eklendi.",
    Please_select_a_topic: "Lütfen bir konu seçin",
    Term_was_not_found: "Terim bulunamadı.",
    Text: "Metin",
    Value: "Değer",
    Search_Word: "Kelime Ara",
    Words: "Kelime",
    Actions: "İşlem",
    _info: {
      content: "Bu sayfada, yeni terimler ekleyebilirsiniz.",
    },
  },
  /***********************************/
  _file_share: {
    File_Share: "Dosya Paylaşımı",
    File_sharing: "Dosya paylaşımı",
    Register: "Kayıt",
    _info: {
      Password: "Şifre",
      content: "Bu sayfada, dosyalarınızı paylaşabilirsiniz.",
      Register: "Kayıt"
    },
    Add_New_Foler: "Yeni Klasör Oluştur",
    Selected_Folder: "Seçili Klasör",
    Create_Folder: "Klasör Oluştur",
    File_Upload: "Dosya Yükleme",
    Upload: "Yükle",
    Add_Folder: "Klasör Ekle",
    Stop_File_Sharing: "Dosya Paylaşımını Durdur",
    Share_Files: "Dosyaları Paylaş",
    Available_users: "Mevcut Kullanıcılar",
    content1: "Dokümanlarınızın güvenliği ve bu sayfadaki özellikleri kullanabilmeniz için şifre tanımlamanız gerekmektedir.",
    content2: "Bu şifreyi kullanarak dokümanlarınızı indirebilir ve paylaşabilirsiniz.",
    content3: "Şifrenizi sistemimizde saklamadığımız için lütfen ulaşabileceğiniz bir yerde şifrenizi saklayınız.",
    content4: "Şifrenizi kaybetmeniz durumunda dosyalarınıza ulaşamaz ve tekrar geri dönderemezsiniz.",
    Please_enter_your_password: "Lütfen şifrenizi giriniz",
    Folder_Name: "Klasör Adı",
    Please_select_a_file: "Dosya Seçiniz",
    Search_Folder: "Klasör Arama",
    Search_File: "Dosya Arama",
    Download: "İndir",
    Share: "Paylaş",
    Stop_sharing_this_file_with_me: "Dosyanın benimle paylaşımını durdur",
    Shared_Users: "Paylaşılan Kullanıcılar",
    Stop_File_Share: "Dosya Paylaşımını Durdur",
    Share_File: "Dosyaları Paylaş",
    Search_User: "Kullanıcı Ara",
    Enter_Password: "Şifre Giriniz",
    Confirm_Password: "Tekrar Şifre Giriniz",
    Password: "Şifre",
    Shared: "Paylaşım",
    File_Name: "Dosya Adı",
    Size: "Dosya Boyutu",
    Actions: "İşlemler",
    User_Name: "Kullanıcı Adı",
    File_Sharing_Stopped: "Dosya paylaşımı durduruldu",
    File_successfully_shared: "Dosya başarıyla paylaşıldı",
    File_was_not_found: "Dosya bulunamadı",
    No_Data: "Veri Yok",
    Selected_file_will_be_removed_from_you: "Seçilen dosya sizden silinecek",
    File_successfuly_removed_from_you: "Dosya sizden başarıyla silindi",
    Folder_successfuly_created: "Klasör başarıyla oluşturuldu",
    Selected_folder_will_be_removed: "Seçilen klasör silinecek",
    Folder_successfuly_deleted: "Klasör başarıyla silindi",
    File_successfuly_uploaded: "Dosya başarıyla yüklendi",
    Selected_file_will_be_removed: "Seçilen dosya silinecek",
    File_successfuly_deleted: "Dosya başarıyla silindi"
  },

  /***********************************/
  _edit_profile: {
    Profile: "Profil",
    Edit_Profile: "Profili Düzenle",
    Subtitle: "Profil bilgilerinizi güncelleyebilirsiniz",
    First_Name: "Ad",
    Last_Name: "Soyad",
    User_Name: "Kullanıcı Adı",
    EMail: "E-posta",
    Clear: "Temizle",
    Save: "Kaydet",
    Profile_is_edited: "Profil düzenlendi",
    Tour_Change_Profile_Picture: "Profil Fotoğrafı",
    Tour_Change_Profile_Picture_Content: "Profil fotoğrafınızı değiştirebilirsiniz",
    Tour_Not_Allowed: "Kullanıcı adı",
    Tour_Not_Allowed_Content: "Kullanıcı adınızı değiştiremezsiniz.",
    Tour_Save: "Kaydet",
    Tour_Save_Content: "Değişikliklerinizi kaydedebilirsiniz."
  },

  /***********************************/
  _tour: {
    Previous: "Önceki",
    Next: "Sonraki",
    Skip: "Atla",
    Finish: "Bitir"
  },

  /***********************************/
  _profile_dropdown: {
    Available: "Müsait",
    Profile: "Profil",
    Logout: "Çıkış"
  },

  /***********************************/
  _notification_menu: {
    New: "Yeni",
    App_Notifications: "Uygulama Bildirimi",
    New_Exam: "Yeni Sınav",
    You_have_a_new_exam_on: "Yeni sınavınız var",
    New_meeting_assigned: "Yeni toplantı atandı",
    You_got_new_meeting_on: "Yeni toplantınız var",
    Expiration_alert: "Sona erme uyarısı",
    Your_exam_will_be_expired_on: "Sınavınız sona erecek",
    View_all_notifications: "Tüm bildirimleri gör"
  },
  /***********************************/
  _score_answer: {
    Score_Answers: "Cevap Puanlama",
    Score_Answers_Text: "Sınavlardaki açık uçlu soruları bu sayfada puanlayabilirsiniz.",
    Score: "Puan",
    Score_Tooltip: "Cevabı Puanla",
    Success_Message: "Başarıyla puanlandı",
    Publish_Success: "Sınav sonuçları başarıyla yayınlandı.",
  },
  /***********************************/
  _register: {
    Create_Account: "Hesap Oluştur",
    Subtitle: "Yeni hesap oluşturmak için aşağıdaki alanları doldurunuz.",
    First_name: "Ad",
    Last_name: "Soyad",
    User_name: "Kullanıcı Adı",
    Email: "Eposta",
    Password: "Parola",
    ConfirmPassword: "Parola Tekrar",
    Accept_terms_conditions: "Şartları ve koşulları kabul ediyorum.",
    Login: "Oturum Aç",
    Register: "Kayıt Ol",
    You_are_already_logged_in: "Zaten giriş yaptınız!",
    Login_Attempt: "Giriş denemesi",
    message: "Kayıdı tamamlamak için lütfen epostanızı kontrol ediniz.",
    User_already_exists: "Kullanıcı zaten mevcut!",
    Please_check_your_Email_or_Username: "Lütfen kullanıcı adı ve eposta adresiniz kontrol ediniz"
  }
};

export default tr_Tr;
