import FORM_VALIDATIONS from "./form_validation";

function LANGUAGE_HELPER(parent) {
  this.parent = parent;
}

LANGUAGE_HELPER.prototype = {
  getLangText: function(_text) {
    return this.parent.app.$t(_text);
  }
}

export default LANGUAGE_HELPER;
