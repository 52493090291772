function ERROR_HANDLER(parent) {
  this.parent = parent;
  this.errorList = {
    400: {defaultTitle: "400", defaultMessage: "Error 400"},
    401: {defaultTitle: "401", defaultMessage: "Error 401"},
    404: {defaultTitle: "404", defaultMessage: "Error 404"},
    402: {defaultTitle: "402", defaultMessage: "Error 402"},
    409: {defaultTitle: "Conflict", defaultMessage: "Error 409"},
    500: {defaultTitle: "500", defaultMessage: "Error 500"},
    502: {defaultTitle: "Error", defaultMessage: "Can not connect the server."},
    "error": {defaultTitle: "Error", defaultMessage: "Error"}
  }
}

ERROR_HANDLER.prototype = {

  check: function (requestResult,message=null) {
    // check if request contains status with error
    if (requestResult) {
      requestResult.status = requestResult.status || "error"; // if status not defined set as error
      if (requestResult && this.errorList.hasOwnProperty(requestResult.status)) {
        this.print(requestResult,message);
        return false;
      }
      else {
        return true;
      }
    } else {
      return true;
    }
  },
  print: function (request,staticMessage = null) {

    // get error response
    let error = request ? request.data || {} : {};

    let code = error.HttpStatusCode || undefined;

    if (code) {
      let title = error.Title || (this.errorList[code].defaultTitle || 'Error');
      let message = staticMessage ||error.Message || (this.errorList.hasOwnProperty(code) ? (error.Message || this.errorList[code].defaultMessage) : 'Unknown error...');

      switch (request.status) {

        case 400:
          this.parent.notify.error({title: title, message: message});
          break;

        case 401:
          this.parent.notify.error({title: title, message: message});
          window.location.href = "/#/pages/login";
          break;

        case 402:
          this.parent.notify.error({title: title, message: message});
          break;

        case 404:
          this.parent.notify.error({title: title, message: message});
          break;

        case 409:
          this.parent.notify.warning({title: title, message: message});
          break;

        case 500:
          this.parent.notify.error({title: title, message: message});
          break;

        case 502:
          this.parent.notify.error({title: title, message: message});
          break;
        default:
          this.parent.notify.error({title: title, message: message});

      }
    }


  }

}

export default ERROR_HANDLER;
