<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6" sm="3" md="3">
          <v-select
            v-model="filter.selectedLevel"
            name="Level"
            :items="levelList"
            placeholder="Kademe"
            item-text="label"
            item-value="value"
            :clearable="true"
          />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select
            v-model="filter.selectedUnit"
            name="Unit"
            :items="unitList"
            placeholder="Birim"
            item-text="label"
            item-value="value"
            :clearable="true"
          />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select
            v-model="filter.selectedTitle"
            name="Title"
            :items="titleList"
            placeholder="Ünvan"
            item-text="label"
            item-value="value"
            :clearable="true"
          />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select
            v-model="filter.selectedProvince"
            name="Province"
            :items="provinceList"
            placeholder="İl"
            item-text="label"
            item-value="value"
            :clearable="true"
          />
        </v-col>
        <v-col cols="6" sm="3" md="3">
          <v-select
            v-model="filter.selectedDistrict"
            name="District"
            :items="districtList"
            placeholder="İlçe"
            item-text="label"
            item-value="value"
            :clearable="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SearchUser",
  data() {
    return {
      provinceList: [],
      districtList: [],
      levelList: [],
      unitList: [],
      titleList: [],
      filter: {
        selectedProvince: "",
        selectedDistrict: "",
        selectedLevel: "",
        selectedUnit: "",
        selectedTitle: "",
      },
    };
  },
  methods: {
    getProvinceList() {
      this.$goc.request.get(this.$enums.API.GetProvinces, (result) => {
        if (result.IsSuccess === true) {
          this.provinceList = result.Result.Provinces.map((d) => ({
            label: d.ProvinceName,
            value: d.Id,
          }));
        }
      });
    },
    getLevelList() {
      this.$goc.request.get(this.$enums.API.GetUserLevels, (result) => {
        if (result.IsSuccess === true) {
          this.levelList = result.Result.Levels.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    getUnitList() {
      this.$goc.request.get(this.$enums.API.GetUserUnits, (result) => {
        if (result.IsSuccess === true) {
          this.unitList = result.Result.Units.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    getTitleList() {
      this.$goc.request.get(this.$enums.API.GetUserTitles, (result) => {
        if (result.IsSuccess === true) {
          this.titleList = result.Result.Titles.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    closeSearchAddDialog() {
      this.dialog = false;
    },
  },
  mounted(){
    this.getProvinceList();
    this.getLevelList();
    this.getUnitList();
    this.getTitleList();
  },
  watch: {
    'filter.selectedProvince'() {
      if (this.filter.selectedProvince) {
        let query = `${this.filter.selectedProvince}`;
        this.$goc.request.get(this.$enums.API.GetDisricts + query, (result) => {
          if (result.IsSuccess === true) {
            this.districtList = result.Result.Districts.map((d) => ({
              label: d.DistrictName,
              value: d.Id,
            }));
          }
        });
      } else {
        this.districtList = [];
        this.filter.selectedDistrict = "";
      }
    },
    filter: {
      deep: true,
      handler() {
        this.$emit("change", this.filter);
      },
    },
  },
  
};
</script>

<style>
</style>