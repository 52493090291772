<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="
          card_header_props.helper.page_helper =
            !card_header_props.helper.page_helper
        "
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{
            this.$t("_common.How_can_I_use_this_form")
          }}</v-card-title>

          <v-card-text>{{
            this.$t("_user_assignments._info.content")
          }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="card_header_props.helper.page_helper = false"
              >{{ this.$t("_common.OK") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->

      <v-row class="mx-2">
        <v-col cols="12" sm="12" md="3" class="my-2 px-1">
          <v-card>
            <v-sheet class="px-4 pt-2">
              <div>
                <vs-button
                  v-if="pageType == this.$enums.ASSIGNMENT_TYPES.Meetings"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="ml-1"
                  @click="addNewMeeting()"
                  >{{ $t("_exam_management.Meeting") }}
                </vs-button>

                <vs-button
                  v-if="pageType == this.$enums.ASSIGNMENT_TYPES.Classes"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="ml-1"
                  @click="promptAddNewClass()"
                  >{{ $t("_class_assignments.Class") }}
                </vs-button>
                <v-text-field :value="pageTypeMapping[pageType]" disabled>
                </v-text-field>
              </div>

              <v-text-field
                v-model="search"
                :label="this.$t('_user_assignments.Search_Directory')"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-sheet>
            <v-card-text style="height: 620px; overflow: auto">
              <v-treeview
                :items="treeData"
                v-model="treeDataModel"
                :search="search"
                activatable
                rounded
                hoverable
                open-all
                item-text="Title"
                item-key="Id"
                multiple="false"
                selection-type="independent"
                item-children="Children"
                color="accent"
                style="min-width: fit-content"
                @update:active="onSelectedUser"
              >
                <template v-slot:label="{ item }">
                  {{ item.Title.split(">")[0] }}
                  <v-icon v-if="item.Title.split('>')[1]" size="14"
                    >mdi-chevron-right</v-icon
                  >
                  {{ item.Title.split(">")[1] }}
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="my-2 px-1">
          <v-card>
            <v-card-title>{{
              this.$t("_user_assignments.Available_users")
            }}</v-card-title>
            <SearchUser @change="onFilterChange" />
            <v-text-field
              style="padding: 10px 20px 0px 20px"
              v-model="searchAllUsers"
              append-icon="search"
              :label="this.$t('_user_assignments.Search')"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              v-model="selectedUsers"
              :items="availableUsers"
              :options.sync="tableOptions"
              :server-items-length="totalUserCount"
              ref="sortableAll"
              item-key="Id"
              :search="searchAllUsers"
              show-select
              @input="showSelected"
              :no-results-text="this.$t('_common.No_Data')"
              :no-data-text="this.$t('_common.No_Data')"
              :footer-props="{
                'items-per-page-text': this.$t('_common.Rows_per_page'),
              }"
              height="393"
            >
              <template
                v-slot:item.data-table-select="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  :value="isSelected || item.isAssigned"
                  :readonly="item.isAssigned"
                  :disabled="item.isAssigned"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="1" class="my-2 pl-4">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col>
                <v-btn text icon color="green" @click="addRight">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn text icon color="red" @click="addLeft">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="12" sm="12" md="4" class="my-2 px-1">
          <v-card>
            <v-card-title>{{
              this.$t("_user_assignments.Users_in_class")
            }}</v-card-title>
            <v-text-field
              style="padding: 10px 20px 0px 20px"
              v-model="searchAssigned"
              append-icon="search"
              :label="this.$t('_user_assignments.Search')"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              v-model="selectedAssigned"
              :items="assignedUsers"
              ref="sortableAssigned"
              item-key="Id"
              :search="searchAssigned"
              :no-results-text="this.$t('_common.No_Data')"
              :no-data-text="this.$t('_common.No_Data')"
              :footer-props="{
                'items-per-page-text': this.$t('_common.Rows_per_page'),
              }"
              show-select
              height="582"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    <v-dialog v-model="showClassDialog" width="1000">
      <v-card>
        <ClassManagement @updatedClassList="updateClassList" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
require("../../assets/css/neo.css");

import ClassManagement from "@/views/neo/admin/class/ClassManagement";
import SearchUser from "@/views/neo/admin/user/SearchUser";
import debounce from 'lodash.debounce';


export default {
  components: {
    ClassManagement,
    SearchUser,
  },
  props: {
    page: String,
  },
  name: "AssignTopic",
  data() {
    return {
      userFilter: {},
      tableOptions: {},
      totalUserCount: 0,
      showClassDialog: false,
      pageVisible: false,
      pageType: "",
      treeData: [],
      treeDataModel: [],
      selectedTopic: [],
      selectedExam: [],
      selectedMeeting: [],
      selectedClass: [],
      selectedUsers: [],
      selectedAssigned: [],
      headers: [
        {
          text: this.$t("_user_assignments.Name"),
          align: "left",
          sortable: false,
          value: "UserName",
        },
      ],
      assignedUsers: [],
      allUsers: [],
      userRole: 0,
      availableUsers: [],
      searchAllUsers: "",
      searchAssigned: "",
      isSelectable: true,
      selectPageType: [
        this.$t("_user_assignments.Topics"),
        this.$t("_user_assignments.Exams"),
        this.$t("_user_assignments.Meetings"),
        this.$t("_user_assignments.Classes"),
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      search: null,
      pageTypeMapping: {
        Topics: this.$t("_user_assignments.Topics"),
        Exams: this.$t("_user_assignments.Exams"),
        Meetings: this.$t("_user_assignments.Meetings"),
        Classes: this.$t("_user_assignments.Classes"),
      },
    };
  },
  methods: {
    onSearchTextChanged: debounce(function() {
      this.getUsers();
    }, 750),
    onFilterChange(filter) {
      this.userFilter = filter;
    },
    updateClassList() {
      this.getClasses();
    },
    getTopicsForTeacher() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let courses = response.Result.Topics;
        for (var i = 0; i < courses.length; i++) {
          let lessons = courses[i].Parent.Title;
          let modules = courses[i].Parent.Parent.Title;
          courses[i].Title =
            courses[i].Title + " (" + modules + ">" + lessons + ")";
          courses[i].Children = [];
          this.treeData.push(courses[i]);
        }
      });
    },
    getTopicsForAdmin() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let modules = response.Result.Topics;
        for (var i = 0; i < modules.length; i++) {
          let lessons = modules[i].Children;
          for (var ii = 0; ii < lessons.length; ii++) {
            let courses = lessons[ii].Children;
            for (var iii = 0; iii < courses.length; iii++) {
              courses[iii].Title =
                courses[iii].Title +
                " (" +
                modules[i].Title +
                ">" +
                lessons[ii].Title +
                ")";
              courses[iii].Children = [];
              this.treeData.push(courses[iii]);
            }
          }
        }
      });
    },
    getExams() {
      this.$goc.request.get(this.$enums.API.QuestionSheet, (response) => {
        this.treeData = [];
        response.Result.Sheets.forEach((element) => {
          if (
            this.$moment(element.ExpirationDate).valueOf() >
            new Date().getTime()
          ) {
            this.treeData.push(element);
          }
        });
      });
    },
    getClasses() {
      this.$goc.request.get(this.$enums.API.Class, (response) => {
        this.treeData = [];
        response.Result.classes.forEach((element) => {
          this.treeData.push({ Title: element.Name, Id: element.Id });
        });
      });
    },
    getMeetings() {
      let body = {};
      this.$goc.request.post(this.$enums.API.ListMeeting, body, (response) => {
        this.treeData = [];
        response.Result.Meetings.forEach((element) => {
          if (
            this.$moment(element.StartDate)
              .add(element.Duration.replace(/\D/g, ""), "s")
              .valueOf() > new Date().getTime()
          ) {
            this.treeData.push(element);
          }
        });
      });
    },
    showSelected() {
      this.$goc.console.log(this.selectedUsers);
    },
    existsInAssigned(item) {
      for (var i = 0, len = this.assignedUsers.length; i < len; i++) {
        if (item.Id == this.assignedUsers[i].Id) return true;
        if (i == len) return false;
      }
    },
    loadUsersByType() {
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        return this.getUsersByTopic();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_topic
      ) {
        return this.getTeachersByTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        return this.getUsersByExam();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_exam
      ) {
        return this.getTeachersByExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        return this.getUsersByMeeting();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Classes) {
        return this.getUsersByClass();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Manager_Of_Classes
      ) {
        return this.getUsersByClass();
      }
    },
    getUsers() {
      this.allUsers = [];
      this.availableUsers = [];
      console.log(this.tableOptions);

      if (
        this.selectedTopic != null ||
        this.selectedExam != null ||
        this.selectedMeeting != null ||
        this.selectedClass != null
      ) {
        const userBody = {
          Limit: this.tableOptions.itemsPerPage,
        };
        if (this.tableOptions.page > 0) {
          userBody.Offset = this.tableOptions.page - 1;
        } else {
          userBody.Offset = 0;
        }
        userBody.UserRole = this.userRole;
        userBody.Status = 2;
        if (this.userFilter.selectedProvince) {
          userBody.ProvinceId = this.userFilter.selectedProvince;
        }
        if (this.userFilter.selectedDistrict) {
          userBody.DistrictId = this.userFilter.selectedDistrict;
        }
        if (this.userFilter.selectedLevel) {
          userBody.Level = this.userFilter.selectedLevel;
        }
        if (this.userFilter.selectedTitle) {
          userBody.Title = this.userFilter.selectedTitle;
        }
        if (this.userFilter.selectedUnit) {
          userBody.Unit = this.userFilter.selectedUnit;
        }
        if(this.searchAllUsers){
          userBody.SearchText = this.searchAllUsers;
        }
        this.$goc.request.post(this.$enums.API.GetAllUsers, userBody, {
          then: (response) => {
            this.allUsers = response.Result.Users;
            this.totalUserCount = response.Result.Count;
          },
          final: () => {
            this.loadUsersByType().then((assignedUsers) => {
              if (assignedUsers != []) {
                this.availableUsers = this.allUsers.map(function (el) {
                  return {
                    ...el,
                    isAssigned: !!assignedUsers.find((u) => u.Id === el.Id),
                  };
                });

                // this.availableUsers = this.allUsers.filter(function (el) {
                //   return !assignedUsers.find((u) => u.Id === el.Id);
                // });
              } else this.availableUsers = this.allUsers;
            });
          },
        });
      }
      console.log(this.userFilter);
    },

    addRight(item) {
      this.$goc.console.log("right");
      this.$goc.console.log(this.selectedUsers);
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.assignUserToTopic();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_topic
      ) {
        return this.assignTeacherToTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.assignUserToExam();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_exam
      ) {
        return this.assignTeacherToExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.assignUserToMeeting();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Classes) {
        this.assignUserToClass();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Manager_Of_Classes
      ) {
        this.assignManagerToClass();
      }
    },
    addLeft() {
      this.$goc.console.log("left");
      this.$goc.console.log(this.selectedAssigned);
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.removeUserFromTopic();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_topic
      ) {
        this.removeTeacherFromTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.removeUserFromExam();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_exam
      ) {
        this.removeTeacherFromExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.removeUserFromMeeting();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Classes) {
        this.removeUserFromClass();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Manager_Of_Classes
      ) {
        this.removeManagerFromClass();
      }
    },

    getUsersByTopic() {
      const _self = this;
      this.assignedUsers = [];
      let query = `${this.selectedTopic}`;
      return new Promise((resolve) => {
        this.$goc.request.get(this.$enums.API.User_Topic + query, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getTeachersByTopic() {
      const _self = this;
      this.assignedUsers = [];
      let query = `${this.selectedTopic}`;
      return new Promise((resolve) => {
        this.$goc.request.get(this.$enums.API.Teacher_Topic + query, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getUsersByExam() {
      const _self = this;
      this.assignedUsers = [];
      let query = `${this.selectedExam}`;
      return new Promise((resolve) => {
        this.$goc.request.get(this.$enums.API.User_Exam + query, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getTeachersByExam() {
      const _self = this;
      this.assignedUsers = [];
      let query = `${this.selectedExam}`;
      return new Promise((resolve) => {
        this.$goc.request.get(this.$enums.API.Teacher_Exam + query, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getUsersByMeeting() {
      const _self = this;
      this.assignedUsers = [];
      let query = `${this.selectedMeeting}`;

      return new Promise((resolve) => {
        this.$goc.request.get(this.$enums.API.User_Meeting + query, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getUsersByClass() {
      const _self = this;
      this.assignedUsers = [];
      let body = {
        TargetId: this.selectedClass[0],
      };
      return new Promise((resolve) => {
        this.$goc.request.post(this.$enums.API.GetUsersFromClass, body, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    getManagersByClass() {
      const _self = this;
      this.assignedUsers = [];
      let body = {
        TargetId: this.selectedClass[0],
      };
      return new Promise((resolve) => {
        this.$goc.request.post(this.$enums.API.GetUsersFromClass, body, {
          then: (response) => {
            if (response.Result !== undefined) {
              this.assignedUsers = response.Result.Users;
            }
            resolve(this.assignedUsers);
          },
        });
      });
    },

    addNewMeeting() {
      this.$goc.page.load("/admin/exam/management?addmeeting=1");
    },

    promptAddNewClass() {
      this.showClassDialog = true;
    },

    onSelectedUser(item) {
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.selectedTopic = item;
        this.userRole = 0;
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_topic
      ) {
        this.selectedTopic = item;
        this.userRole = 3;
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.selectedExam = item;
        this.userRole = 0;
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_exam
      ) {
        this.selectedExam = item;
        this.userRole = 3;
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.selectedMeeting = item;
        this.userRole = 0;
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Classes) {
        this.selectedClass = item;
        this.userRole = 0;
      }

      if (item[0] == null) this.pageVisible = false;
      else {
        this.pageVisible = true;
      }
      if (item.length > 0) {
        this.getUsers();
      }
    },

    assignUserToTopic() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            UserList: userIdList,
            TopicId: this.selectedTopic[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.Topic_User, body, {
            then: (res) => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_topic")
              );
            },
            final: () => {
              this.getUsers();
            },
          });
        }
      );
    },

    assignTeacherToTopic() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            UserList: userIdList,
            TopicId: this.selectedTopic[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.Topic_Teacher, body, {
            then: (res) => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_topic")
              );
            },
            final: () => {
              this.getUsers();
            },
          });
        }
      );
    },

    assignUserToExam() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            UserList: userIdList,
            SheetId: this.selectedExam[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.Exam_User, body, {
            then: () => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_exam")
              );
            },
            final: () => {
              this.getUsers();
            },
          });
        }
      );
    },

    assignTeacherToExam() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            UserList: userIdList,
            SheetId: this.selectedExam[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.Exam_Teacher, body, {
            then: () => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_exam")
              );
            },
            final: () => {
              this.getUsers(this.userRole);
            },
          });
        }
      );
    },

    assignUserToMeeting() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            Users: userIdList,
            MeetingId: this.selectedMeeting[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.Meeting_User, body, {
            then: () => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_meeting")
              );
            },
            final: () => {
              this.getUsers();
            },
          });
        }
      );
    },

    assignUserToClass() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: userIdList,
            TargetId: this.selectedClass[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(this.$enums.API.AddUserToClass, body, {
            then: () => {
              this.successNotify(
                this.$t("_user_assignments.Successfully_added_user_to_class")
              );
            },
            final: () => {
              this.getUsers();
            },
          });
        }
      );
    },

    assignManagerToClass() {
      if (this.selectedUsers.length == 0) return;
      let userIdList = this.selectedUsers.map((e) => e.Id);
      let body = {
        AssigneeList: userIdList,
        TargetId: this.selectedClass[0],
      };
      this.$goc.request.post(this.$enums.API.AddClassManager, body, {
        then: () => {
          this.successNotify("Successfully added manager to class");
        },
        final: () => {
          this.getUsers();
        },
      });
    },

    removeUserFromTopic(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            UserList: userIdList,
            TopicId: this.selectedTopic[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(
            this.$enums.API.Topic_DeleteUser,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_management.UserRemovedSuccessfuly",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeTeacherFromTopic(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            UserList: userIdList,
            TopicId: this.selectedTopic[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(
            this.$enums.API.Topic_DeleteTeacher,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_management.UserRemovedSuccessfuly",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeUserFromExam(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            UserList: userIdList,
            SheetId: this.selectedExam[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };

          this.$goc.request.post(
            this.$enums.API.Exam_DeleteUser,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_assignments.Successfully_removed_user_from_exam",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeTeacherFromExam(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            UserList: userIdList,
            SheetId: this.selectedExam[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };

          this.$goc.request.post(
            this.$enums.API.Exam_DeleteTeacher,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_assignments.Successfully_removed_user_from_exam",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeUserFromMeeting(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            Users: userIdList,
            MeetingId: this.selectedMeeting[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2, // this.$i18.locale
          };
          this.$goc.request.post(
            this.$enums.API.Meeting_DeleteUser,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_assignments.Successfully_removed_user_from_meeting",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeNotification(response, message, isSentMail) {
      if (!response.Message) {
        this.successNotify(this.$t(message));
        this.selectedAssigned = [];
        if (isSentMail) {
          this.$goc.notify.success({
            title: this.$t("_notification.NotificationMail"),
            message: this.$t("_notification.NotificationSentSuccessfully"),
          });
        }
      } else {
        this.$goc.notify.warning({
          title: this.$t("_common.Warning"),
          message: message,
        });
      }
      this.getUsers();
    },

    removeUserFromClass(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          body = {
            AssigneeList: userIdList,
            TargetId: this.selectedClass[0],
            isSendMail: isSentMail,
            Language: localStorage.getItem("lmsSelectedLang") == "tr" ? 1 : 2,
          };
          this.$goc.request.post(
            this.$enums.API.RemoveUserFromClass,
            body,
            (response) => {
              if (response.Message)
                this.removeNotification(response, response.Message, isSentMail);
              else {
                this.removeNotification(
                  response,
                  "_user_assignments.Successfully_removed_user_from_class",
                  isSentMail
                );
              }
            }
          );
        }
      );
    },

    removeManagerFromClass(item) {
      if (this.selectedAssigned.length == 0) return;
      let userIdList = this.selectedAssigned.map((e) => e.Id);
      let body = {
        AssigneeList: userIdList,
        TargetId: this.selectedClass[0],
      };

      this.$goc.request.post(this.$enums.API.RemoveClassManager, body, {
        then: () => {
          this.successNotify(
            this.$t("_user_assignments.Successfully_removed_user_from_exam")
          );
          this.selectedAssigned = [];
        },
        final: () => {
          this.getUsers();
        },
      });
    },

    selectedPageType(item) {
      this.pageType = this.$route.params.type || this.page;
      // this.$route.path.split("/")[this.$route.path.split("/").length - 1];
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_topic) {
        this.getTopicsForAdmin();
      } else if (
        JSON.parse(localStorage.getItem("userInfo")).userRole == "LmsTeacher" &&
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics
      ) {
        this.getTopicsForTeacher();
      } else if (
        JSON.parse(localStorage.getItem("userInfo")).userRole ==
          "LmsAdministrator" &&
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics
      ) {
        this.getTopicsForAdmin();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams ||
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Teachers_to_exam
      ) {
        this.getExams();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.getMeetings();
      } else if (
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Classes ||
        this.pageType == this.$enums.ASSIGNMENT_TYPES.Manager_Of_Classes
      ) {
        this.getClasses();
      }
    },

    errorNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.ThereWasProblem"),
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },

    warningNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Warning"),
        text: message,
        iconPack: "feather",
        icon: "icon-warning-circle",
        color: "warning",
      });
    },

    successNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Success"),
        text: message,
        iconPack: "feather",
        icon: "icon-success-circle",
        color: "success",
      });
    },
    clearData() {
      this.treeData = [];
      this.availableUsers = [];
      this.allUsers = [];
      this.assignedUsers = [];
      this.selectedPageType();
    },
  },
  mounted() {
    this.$goc.setModule("AssignTopic", this);
    this.selectedPageType();
  },
  watch: {
    "$route.path": 'clearData',
    tableOptions: {
      deep: true,
      handler() {
        this.getUsers();
      },
    },
    userFilter: {
      deep: true,
      handler() {
        this.getUsers();
      },
    },
    searchAllUsers(){
      this.onSearchTextChanged();
    },
  },
  computed: {
    card_header_props() {
      if (
        this.pageType === "TeachersTopic" ||
        this.pageType === "TeachersExam"
      ) {
        return {
          header: {
            headLine: this.$t("_user_assignments.Teacher_Assignments"),
            subTitle: this.$t("_user_assignments.Teacher_Management_Text"),
            icon: "mdi-account-check",
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false,
          },
        };
      }
      return {
        header: {
          headLine: this.$t("_user_assignments.User_Assignments"),
          subTitle: this.$t("_user_assignments.User_Management_Text"),
          icon: "mdi-account-check",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      };
    },
    indexedItems() {
      return this.allUsers.map((item, index) => ({
        index: index,
        ...item,
      }));
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
};
</script>

<style scoped>
.scroll-area-topics {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}
</style>
